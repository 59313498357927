import _ from 'lodash';
window._ = _;

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'
// import Pusher from 'pusher-js'
// window.Pusher = Pusher
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
//     forceTLS: false,
//     encrypted: true,
//     wsHost: '127.0.0.1',
//     wsPort: import.meta.env.VITE_PUSHER_APP_PORT,
//     wssPort: import.meta.env.VITE_PUSHER_APP_PORT,
//     disableStats: true,
//     enabledTransports: ["ws", "wss"],
// })

import Echo from 'laravel-echo'
import io from 'socket.io-client'
window.io = io
const port = import.meta.env.VITE_PUSHER_APP_PORT
window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: window.location.hostname + port ? ':' + port : '',
    transports: ['websocket', 'polling', 'flashsocket'] // Fix CORS error!
})

// import Echo from '@ably/laravel-echo'
// import * as Ably from 'ably'
//
// window.Ably = Ably; // make globally accessible to Echo
// window.Echo = new Echo({
//     broadcaster: 'ably',
// })
// window.Echo.connector.ably.connection.on(stateChange => {
//     if (stateChange.current === 'connected') {
//         console.log('💬 connected to ably server');
//     }
// })
