<script setup>
import Breadcrumbs from "@/Shared/Breadcrumbs.vue";
import Button from "@/Components/Button.vue";
import ModalLink from "@/Shared/ModalLink.vue";
//import Alert from "@/Components/Alert.vue";
import {useForm, router, usePage} from "@inertiajs/vue3";
import InputError from "@/Components/InputError.vue";
import {computed, nextTick, onMounted, ref} from "vue";
import {useToast} from "vue-toastification";
import Loader from "@/Shared/Loader.vue";
import swal from "@/swal.js";
import TableActionButton from "@/Shared/TableActionButton.vue";
//import SimpleBar from 'simplebar-vue'
import DialogModal from "@/Components/DialogModal.vue";
import SecondaryButton from "@/Components/SecondaryButton.vue";
import ToggleSwitch from "@/Components/ToggleSwitch.vue";
import TwoWayToggleSwitch from "@/Components/TwoWayToggleSwitch.vue";
import InputInfoText from "@/Components/InputInfoText.vue";
//import VueMarkdown from 'vue-markdown-render'
import Logo from '@/Components/Logo.vue'

// Model variables
const modelTitle = 'Chat'
const modelTitlePlural = 'Chats'
const modelTitleLower = 'chat'
const routeBase = 'chat'

const props = defineProps([
    'title',
    'description',
    'type',
    'model',
    'variables',
])

const user = computed(() => usePage().props.auth.user)

const form = useForm({
    message: '',
    include_history: false,
    enhance_prompt: false,
    html_convert: false,
    persona: null,
    file: null,
})
const isReadyToSubmit = ref(true)
const pondRef = ref()
const pondFileName = ref('image-edit')
const imageInputError = ref(null)

const scrollable = ref()
const editable = ref()
const selectedHistory = ref(null)
const selectedImage = ref(null)
const showingHistoryDetails = ref(false)
const showingImageDetails = ref(false)
const imageType = ref(false)
const imageVariant = ref(false)
const chatHistory = ref(props.model.history || [])
const timeout = ref(null)

const shouldShowHistoryToggle = computed(() => {
    return (props.type === 'chat' || props.type === 'vision')
        ? chatHistory.value.length
        : form.include_history && chatHistory.value.length
})

const enableImageEdit = computed(() => {
    return false // disabled for now, not fully tested...

    return props.model.type !== 'chat' && props.model.type !== 'vision'
})

const showTextInput = computed(() => {
    return (!imageType.value || (imageType.value && !imageVariant.value)) && !form.html_convert
})

const scrollToBottom = () => {
    if (!scrollable.value) return
    // Set delay, and prevent double-runs
    if (timeout.value) {
        clearTimeout(timeout.value)
    }
    timeout.value = setTimeout(() => {
        const contentHeight = scrollable.value.scrollHeight
        scrollable.value.scrollTo({
            top: contentHeight,
            behavior: "smooth",
        })
    }, 500)
}

onMounted(() => {
    Echo.private(`App.Models.Chat.${props.model.id}`)
        .listen('ChatHistoryUpdated',(params) => {
            // Get the event params
            const { history, message, complete, errors } = params

            // If errors or complete, fetch full details from the database
            if (errors || complete) {
                getHistory()
                return
            }

            // Add history item if not present
            if (chatHistory.value.findIndex(h => h.id === history.id) === -1) {
                chatHistory.value.push({
                    ...history,
                    response: message,
                    completed: complete
                })
            } else {
                // Else update it...
                chatHistory.value = chatHistory.value.map(h => {
                    if (h.id === history.id) {
                        return {
                            ...h,
                            response: message,
                            completed: complete
                        }
                    }
                    return h
                })
            }
            // Wait a "tick", then scroll to the bottom of the window as content is generating...
            nextTick(() => scrollToBottom())
        })

    nextTick(() => {
        // Scroll to bottom of chat history on mount
        scrollToBottom()

        // Focus on the editable div
        editable.value?.focus()
    })

    // If model type is vision, change default image type
    if (props.model.type === 'vision') {
        imageType.value = true
    }
})

const getCrumbs = () => {
    return props.variables.section === 'user' ?
        [
            {title: modelTitlePlural, href: route(`${routeBase}.index`)},
            {title: props.title, href: null},
        ] : [
            {title: 'Admin', href: null},
            {title: modelTitlePlural, href: route(`${routeBase}.admin.index`)},
            {title: props.title, href: null},
        ]
}

const copyResponseToInput = (history) => {
    form.message = history.response
}

const getHistory = () => {
    axios.get(route('history.index', {chat: props.model.id}))
        .then(response => {
            chatHistory.value = response.data
            scrollToBottom()
        })
}

const submit = () => {
    // Get the file from the filepond hidden input
    // const files = pondRef.value?.getFiles()
    const file_location = document.querySelector("input[name='" + pondFileName.value + "']")?.value

    // Reset file input error
    imageInputError.value = null

    // Check if image type is selected, and if so, if a file is present
    if (imageType.value && !file_location.length) {
        useToast().error('Missing image, please add one and try again...')
        imageInputError.value = 'Image field is required'
        return
    }

    // Submit the form
    form.transform((data) => ({
        ...data,
        file_location,
        image_type: imageType.value ? 'image' : 'text',
        image_action: imageVariant.value ? 'variant' : 'edit',
    })).post(route('history.store', { chat: props.model.id }), {
        preserveScroll: false,
        onSuccess: () => {
            // Clear files
            pondRef.value?.removeFiles()

            // Reset form
            form.message = ''
            form.enhance_prompt = false
            form.html_convert = false
            form.file = null

            // Update image type
            // Default true for vision, false for all other types
            imageType.value = props.model.type === 'vision'
            imageVariant.value = false
        },
        onError: (error) => { useToast().error(Object.values(error).join(" ")) }
    })
}

const showDetails = (history) => {
    selectedHistory.value = history
    showingHistoryDetails.value = true
}

const previewImage = (media) => {
    selectedImage.value = media
    showingImageDetails.value = true
}

const downloadImage = (media) => {
    window.open(route('document.show', media.id), '_blank')
}

const copyToClipboard = async (text, message = "Copied to clipboard successfully") => {
    await navigator.clipboard.writeText(text)
    swal.fire({
        title: message,
        icon: 'success',
    })
}

const createVariation = (history, media) => {
    swal.fire({
        title: 'Create variation',
        text: 'Create a variation for the selected image?',
        icon: 'info',
        showCancelButton: true,
    }).then((result) => {
        if (result.isConfirmed) {
            router.post(route('history.variant.store', {
                chat: props.model.id,
                history: history.id,
                media: media.id
            }), {}, {
                preserveScroll: false,
                onSuccess: () => {  },
                onError: (error) => { useToast().error(Object.values(error).join(" ")) }
            })
        }
    })
}

const destroy = (model) => {
    swal.fire({
        title: `Delete ${modelTitleLower} history item`,
        text: 'This action cannot be undone',
        icon: 'warning',
        showCancelButton: true,
    }).then((result) => {
        if (result.isConfirmed) {
            form.delete(route('history.destroy', { chat: props.model.id, history: model.id }), {
                preserveScroll: false,
                onSuccess: () => {
                    getHistory()
                    scrollToBottom()
                },
                onError: (error) => { useToast().error(Object.values(error).join(" ")) }
            })
        }
    })
}
</script>

<template>
    <Head :title="title" />

    <div>
        <Breadcrumbs :links="getCrumbs()">
            <template #actions>
                <li class="flex items-center ml-auto">
                    <ModalLink
                        :href="model.edit_url"
                        :refresh="false"
                        class="inline-flex items-center px-2 py-1 bg-white border border-gray-300 rounded-md font-semibold text-xs text-gray-700 uppercase tracking-widest shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:opacity-25 transition ease-in-out duration-150"
                    >
                        <svg class="w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                        </svg>
                    </ModalLink>
                </li>
            </template>
        </Breadcrumbs>

        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="">
                <div class="max-w-5xl mx-auto flex-1 justify-between flex flex-col" style="height: calc(100vh - 130px)">
                    <div ref="scrollable" id="messages" class="relative z-10 flex-grow flex flex-col space-x-2 space-y-4 px-2 overflow-x-hidden overflow-y-auto">
                        <template v-if="!chatHistory.length">
                            <div class="relative py-4 px-4 w-full h-full">
                                <div class="flex flex-col gap-4 items-center justify-center text-gray-500 w-full h-full">
                                    <Logo size-class="w-32" />

                                    <p class="text-xl text-center">
                                        Enter your first message below to get started...
                                    </p>

                                    <svg class="w-6 h-6 animate-bounce" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <template v-for="history in chatHistory">
                            <div class="chat-message my-2">
                                <div class="flex items-end">
                                    <div class="flex flex-col space-y-2 text-sm max-w-2xl mx-2 order-2 items-start">
                                        <div>
                                            <span class="relative pl-4 pr-6 py-3 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600">
                                                <div class="flex items-center gap-3">
                                                    <template v-if="history.image_edit">
                                                        <button type="button" @click="() => previewImage(history.image_edit)" class="border w-12 h-12 transition ease-in-out duration-300 border-2 rounded-full overflow-hidden hover:border-primary-500">
                                                            <img class="h-auto max-w-full" :src="history.image_edit.preview_url" alt="Uploaded">
                                                        </button>
                                                    </template>
                                                    {{ history.message_prompt }}
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="sm:flex gap-x-1">
                                        <TableActionButton
                                            :actions="[
                                                {title: 'Info', action: () => showDetails(history)},
                                                {divider: true},
                                                {title: 'Delete', action: () => destroy(history)},
                                            ]"
                                        />
                                        <img v-if="user && user.profile_photo_url"
                                             :src="user.profile_photo_url"
                                             :alt="user.name" class="w-8 h-8 rounded-full order-1 sm:block hidden"
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="chat-message mb-2">
                                <div class="flex items-end justify-end">
                                    <div class="flex flex-col space-y-2 text-sm max-w-2xl mx-2 order-1 items-end">
                                        <div class="">
                                            <template v-if="history.meta?.errors">
                                                <div class="mb-1">
                                                    <span class="px-4 py-2 rounded-lg inline-block bg-primary-600 text-white">
                                                        Error message received
                                                    </span>
                                                </div>
                                                <div>
                                                    <span class="px-4 py-2 rounded-lg inline-block bg-gradient-to-r rounded-md rounded-br-none border-2 from-yellow-500 to-yellow-400 border-yellow-500">
                                                        <div class="mx-auto">
                                                            <div class="flex items-center justify-between flex-wrap">
                                                                <div class="flex-1 flex items-center min-w-0">
                                                                    <span class="bg-yellow-300 flex bg-opacity-25 p-2 rounded-lg mr-2">
                                                                        <svg class="text-yellow-600 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                                                        </svg>
                                                                    </span>
                                                                    <div class="ml-1 font-medium text-sm text-white">
                                                                        {{ history.meta?.errors.message }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="px-4 py-2 rounded-lg inline-block rounded-br-none bg-primary-600 text-white">
                                                    <div v-if="history.response">
                                                        <div v-if="!history.html_convert" v-html="history.response" />
                                                        <div v-if="history.html_convert">
                                                            <div class="w-[60vw] h-[60vh]">
                                                                <iframe class="w-full h-full" :srcdoc="history.response" style="zoom: 0.5" />
                                                            </div>
                                                            <Button type="button" class="my-4 gap-1" @click="() => copyToClipboard(history.response)">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184" />
                                                                </svg>
                                                                Copy to clipboard
                                                            </Button>
                                                        </div>
                                                        <button
                                                            v-if="history.enhance_prompt"
                                                            type="button"
                                                            @click="() => copyResponseToInput(history)"
                                                            v-tooltip="'Copy text to input'"
                                                            class="rounded-md rounded-md border border-2 border-gray-400 bg-gray-500 hover:bg-gray-600 hover:border-gray-500 p-2"
                                                        >
                                                            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184" />
                                                            </svg>
                                                        </button>
                                                    </div>

                                                    <div class="block w-full text-right" v-if="model.type === 'speech' && history.speech">
                                                        <audio controls>
                                                            <source :src="history.speech.original_url" type="audio/mpeg">
                                                            Your browser does not support the audio element.
                                                        </audio>
                                                        <small class="">voice: {{ history.speech_voice }}</small>
                                                    </div>

                                                    <div class="block w-full" v-if="model.type === 'image' && history.images.length">

                                                        <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
                                                            <div
                                                                v-for="(img, idx) in history.images"
                                                                :key="`img_${history.id}_${idx}`"
                                                            >
                                                                <div class="relative mx-1">
                                                                    <TableActionButton
                                                                        class="absolute bottom-3 left-2"
                                                                        trigger-bg="bg-white bg-opacity-50"
                                                                        :actions="[
                                                                            {title: 'Preview', action: () => previewImage(img)},
                                                                            {title: 'Create Variation', action: () => createVariation(history, img)},
                                                                            // {title: 'Edit', action: () => $openImageEditor(
                                                                            //     img.original_url,
                                                                            //     (newImage) => console.log(newImage)
                                                                            // )},
                                                                            {divider: true},
                                                                            {title: 'Download', action: () => downloadImage(img)},
                                                                        ]"
                                                                    />
                                                                    <button type="button" @click="() => previewImage(img)" class="border transition ease-in-out duration-300 border-2 rounded-lg overflow-hidden hover:border-primary-500">
                                                                        <img class="h-auto max-w-full" :src="img.preview_url" alt="Generated">
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Loader :loading="!history.completed" />
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <img src="/img/logo.svg" alt="MyGPT" class="w-8 h-8 rounded-full order-2">
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="border-t-2 border-gray-100 pt-4 mb-2 sm:mb-0">
                        <div class="">
                            <div v-if="enableImageEdit" class="mb-2 border px-2 py-1 rounded-md border-gray-300 bg-gray-200 gap-4 flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-gray-500">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <TwoWayToggleSwitch
                                    v-model="imageType"
                                    off-name="Text"
                                    on-name="Image"
                                />
                                <TwoWayToggleSwitch
                                    v-if="imageType"
                                    v-model="imageVariant"
                                    off-name="Edit"
                                    on-name="Variation"
                                />
                            </div>
                            <template v-if="imageType">
                                <div class="mb-2">
                                    <FilePond
                                        ref="pondRef"
                                        :name="pondFileName"
                                        @addfilestart="(file) => isReadyToSubmit = false"
                                        @processfile="(file) => isReadyToSubmit = true"
                                    />
                                    <InputError :message="imageInputError" />
                                </div>
                            </template>
                            <form class="relative sm:flex" @submit.prevent="submit">
                                <div
                                    v-if="showTextInput"
                                    ref="editable"
                                    contenteditable="true"
                                    @keydown.enter.prevent="submit"
                                    @input="(e) => form.message = e.target.innerText"
                                    :class="{'pl-12': false}"
                                    class="w-full shadow-inner px-4 sm:pr-40 border-gray-300 focus:border-primary-500 focus:ring-primary-500 focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 bg-gray-200 rounded-md py-3"
                                    v-text="form.message"
                                />
                                <div v-else class="w-full">
                                    <button
                                        type="submit"
                                        :class="{ 'opacity-25': form.processing || !isReadyToSubmit }"
                                        :disabled="form.processing || !isReadyToSubmit"
                                        class="ml-auto flex rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-primary-500 hover:bg-primary-400 focus:outline-none">
                                        <span class="font-bold">
                                            <span v-if="model.type === 'chat'">
                                                Send
                                            </span>
                                            <span v-else-if="model.type === 'vision'">
                                                {{ form.html_convert ? 'Convert to HTML' : 'Send' }}
                                            </span>
                                            <span v-else-if="model.type === 'speech'">
                                                Generate Audio
                                            </span>
                                            <span v-else>
                                                {{ form.enhance_prompt ? 'Generate Prompt' : 'Generate Image' }}
                                            </span>
                                            {{ form.include_history ? ' (w/ history)' : '' }}
                                        </span>
                                    </button>
                                </div>

                                <div v-if="showTextInput"
                                     class="flex mt-2 sm:mt-0 sm:absolute overflow-hidden right-0 items-center inset-y-0 gap-2">
                                    <button
                                        type="submit"
                                        :class="{ 'opacity-25': form.processing || !form.message || !isReadyToSubmit }"
                                        :disabled="form.processing || !form.message || !isReadyToSubmit"
                                        class="-mr-0.5 ml-auto sm:ml-0 self-end inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-primary-500 hover:bg-primary-400 focus:outline-none">
                                        <span class="font-bold">
                                            <span v-if="model.type === 'chat'">
                                                Send
                                            </span>
                                            <span v-else-if="model.type === 'vision'">
                                                Send
                                            </span>
                                            <span v-else-if="model.type === 'speech'">
                                                Generate Audio
                                            </span>
                                            <span v-else>
                                                {{ form.enhance_prompt ? 'Generate Prompt' : 'Generate Image' }}
                                            </span>
                                            {{ form.include_history ? ' (w/ history)' : '' }}
                                        </span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <InputInfoText v-if="model.type === 'image'">
                        For best results, include type (e.g. realistic, photorealistic, expressionist, impressionist, abstract, surrealistic, and pop art), paint medium (e.g. oil, water color, acrylic, gouache, pastel, encaustic, fresco, spray paint, digital) and subject (e.g. history, portrait art, genre, landscape, still life).
                    </InputInfoText>
                    <div v-if="model.type === 'image'" class="mt-3 mb-2">
                        <div class="mb-2">
                            <ToggleSwitch v-model="form.enhance_prompt" name="Generate enhanced prompt" />
                            <InputInfoText class="mt-2">
                                Struggling to come up with an expressive statement? Use AI to generate an enhanced image prompt!
                            </InputInfoText>
                        </div>
                    </div>
                    <div v-else-if="shouldShowHistoryToggle" class="flex gap-3">
                        <div class="mt-3 mb-2">
                            <ToggleSwitch v-model="form.include_history" name="Include history" />
                            <InputInfoText>
                                Include chat history to give additional context?
                            </InputInfoText>
                        </div>
                        <div v-if="model.type === 'vision'" class="mt-3 mb-2">
                            <ToggleSwitch v-model="form.html_convert" name="Convert to HTML" />
                            <InputInfoText>
                                Generate Tailwind CSS styled HTML from the image?
                            </InputInfoText>
                        </div>
                    </div>
                    <div v-else>
                        <div class="mt-3 mb-4"><!-- Spacer --></div>
                    </div>
                    <InputError :message="form.errors.message" />
                </div>
            </div>
        </div>

        <DialogModal :show="showingHistoryDetails" @close="showingHistoryDetails = false">
            <template #title>
                Chat details
            </template>
            <template #content>
                <div v-if="selectedHistory">
                    <div class="px-4 sm:px-0">
                        <h3 class="text-base font-semibold leading-7 text-gray-900">
                            Tokens used
                        </h3>
                        <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                            The tokens used to fulfill this request
                        </p>
                    </div>
                    <div class="my-6 border-t border-gray-100">
                        <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">
                                Prompt Tokens
                            </dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {{ selectedHistory.prompt_tokens || 'N/A' }}
                            </dd>
                        </div>
                        <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">
                                GPT Tokens
                            </dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {{ selectedHistory.completion_tokens || 'N/A' }}
                            </dd>
                        </div>
                        <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">
                                Total Tokens Used
                            </dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {{ selectedHistory.total_tokens || 'N/A' }}
                            </dd>
                        </div>
                    </div>

                    <div class="px-4 sm:px-0">
                        <h3 class="text-base font-semibold leading-7 text-gray-900">
                            Configuration
                        </h3>
                        <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                            The configuration details used to fulfill this request
                        </p>
                    </div>
                    <div class="mt-6 border-t border-gray-100">
                        <dl class="divide-y divide-gray-100">
                            <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt class="text-sm font-medium leading-6 text-gray-900">
                                    Created
                                </dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {{ $filters.getTimeFormatted(selectedHistory.created_at) }}
                                </dd>
                            </div>
                            <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt class="text-sm font-medium leading-6 text-gray-900">
                                    Model
                                </dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {{ selectedHistory.model }}
                                </dd>
                            </div>
                            <template v-if="selectedHistory.type === 'chat' || selectedHistory.type === 'vision'">
                                <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt class="text-sm font-medium leading-6 text-gray-900">
                                        Endpoint
                                    </dt>
                                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {{ selectedHistory.endpoint }}
                                    </dd>
                                </div>
                                <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt class="text-sm font-medium leading-6 text-gray-900">
                                        Role
                                    </dt>
                                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {{ selectedHistory.role }}
                                    </dd>
                                </div>
                                <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt class="text-sm font-medium leading-6 text-gray-900">
                                        Temperature
                                    </dt>
                                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {{ selectedHistory.temperature || 'N/A' }}
                                    </dd>
                                </div>
                                <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt class="text-sm font-medium leading-6 text-gray-900">
                                        Max Tokens
                                    </dt>
                                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {{ selectedHistory.max_tokens || 'N/A' }}
                                    </dd>
                                </div>
                            </template>
                            <template v-else>
                                <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt class="text-sm font-medium leading-6 text-gray-900">
                                        Size
                                    </dt>
                                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {{ selectedHistory.size }}
                                    </dd>
                                </div>
                                <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt class="text-sm font-medium leading-6 text-gray-900">
                                        Items to generate
                                    </dt>
                                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {{ selectedHistory.n_items }}
                                    </dd>
                                </div>
                            </template>
                        </dl>
                    </div>
                </div>
            </template>
            <template #footer>
                <SecondaryButton @click="showingHistoryDetails = false">
                    Close
                </SecondaryButton>
            </template>
        </DialogModal>

        <DialogModal :show="showingImageDetails" @close="showingImageDetails = false">
            <template #title>
                Image preview
            </template>
            <template #content>
                <div class="">
                    <img :src="selectedImage.original_url" alt="Preview" class="w-full h-auto" />
                </div>
            </template>
            <template #footer>
                <SecondaryButton @click="() => downloadImage(selectedImage)">
                    <div class="flex items-center">
                        <svg class="w-5 h-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>
                        Download
                    </div>
                </SecondaryButton>
                <SecondaryButton class="ml-2" @click="showingImageDetails = false">
                    Close
                </SecondaryButton>
            </template>
        </DialogModal>
    </div>
</template>
