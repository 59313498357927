<template>
    <nav class="flex w-100 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-3 border-b border-gray-100 pb-2" aria-label="Breadcrumb">
        <ol class="flex flex-1 gap-4">
            <li class="flex">
                <div class="flex items-center">
                    <inertia-link :href="homeRoute ? homeRoute : route('dashboard')" class="text-sm flex items-center font-medium text-gray-500 hover:text-gray-700">
                        <svg class="flex-shrink-0 h-4 w-4 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path fill-rule="evenodd" d="M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 01.75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 019.75 22.5a.75.75 0 01-.75-.75v-4.131A15.838 15.838 0 016.382 15H2.25a.75.75 0 01-.75-.75 6.75 6.75 0 017.815-6.666zM15 6.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" clip-rule="evenodd" />
                            <path d="M5.26 17.242a.75.75 0 10-.897-1.203 5.243 5.243 0 00-2.05 5.022.75.75 0 00.625.627 5.243 5.243 0 005.022-2.051.75.75 0 10-1.202-.897 3.744 3.744 0 01-3.008 1.51c0-1.23.592-2.323 1.51-3.008z" />
                        </svg>
                        Dashboard
                    </inertia-link>
                </div>
            </li>
            <li v-if="links.length === 0" class="flex">
                <div class="flex items-center">
                    <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                    </svg>
                </div>
            </li>
            <li class="flex" v-for="link in links">
                <div class="flex items-center">
                    <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                    </svg>
                    <template v-if="link.href">
                        <inertia-link :href="link.href" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                            {{ link.title }}
                        </inertia-link>
                    </template>
                    <template v-else>
                        <span class="ml-4 text-sm font-medium text-gray-400">
                            {{ link.title }}
                        </span>
                    </template>
                </div>
            </li>
            <slot name="actions" />
        </ol>
    </nav>
</template>

<script>
export default {
    props: {
        links: {
            type: Array,
            default: () => []
        },
        homeRoute: {
            type: String,
            default: null
        }
    }
}
</script>
