<template>
    <div class="mt-1 flex rounded-md shadow-sm">
        <div class="relative flex items-stretch flex-grow focus-within:z-10">
            <div
                class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg class="h-5 w-5 text-gray-400"
                     xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </div>
            <input
                class="focus:ring-primary-500 focus:border-primary-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                autocomplete="off" type="text" name="search"
                placeholder="Search…" :value="modelValue" ref="search"
                @input="search">
        </div>
        <button @click="$emit('reset')"
                class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100  focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500">
            <svg class="h-5 w-5 text-gray-400"
                 xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
            <span>
                Reset
            </span>
        </button>
    </div>
</template>

<script>

import {debounce} from 'lodash/function'

export default {
    props: {
        modelValue: String,
        focus: {
            type: Boolean,
            default: false
        }
    },

    emits: ['update:modelValue', 'reset'],

    mounted () {
        if (this.focus) {
            this.$nextTick(() => this.$refs.search.focus())
        }
    },

    methods: {
        search: debounce(function ($event) {
            this.$emit('update:modelValue', $event.target.value)
        }, 300)
    }
}
</script>
