<template>
    <div>
        <div class="flex items-center justify-between gap-x-2 py-3">
            <div class="min-w-0">
                <div class="flex items-center justify-between gap-x-2">
                    <div v-if="hasContent && hasContentSlot" class="flex items-center justify-center">
                        <button type="button"
                                @click="toggle"
                                v-tooltip="isOpen ? `Hide ${type}` : `Show ${type}`"
                                :disabled="loading"
                                :class="{ 'opacity-25': loading || false }"
                                class="cursor-pointer text-gray-500 rounded-full h-8 w-8 flex items-center justify-center border border-transparent bg-gray-100 hover:border-gray-200 hover:bg-gray-200">
                            <svg v-if="loading" class="w-6 h-6 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                            </svg>
                            <svg
                                v-else :class="{'rotate-90': isOpen}"
                                class="h-6 w-6 transform transition duration-300 ease-in-out delay-75" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </button>
                    </div>
                    <div class="h-8 w-8" v-else>
                        <!-- spacer for items without content -->
                    </div>

                    <p class="text-lg font-semibold leading-6 text-gray-900">
                        <slot name="title" />
                    </p>

                    <div class="flex items-center justify-start gap-x-2">
                        <slot name="actions" />
                    </div>

                    <div class="flex items-center justify-start gap-x-2">
                        <slot name="meta" />
                    </div>
                </div>
            </div>
        </div>
        <div v-show="isOpen">
            <slot name="content"/>
        </div>
    </div>
</template>

<script>

export default {
    name: 'accordion-item',

    props: {
        open: {
            type: Boolean,
            default: false, // collapsed by default
        },
        type: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        hasContent: {
            type: Boolean,
            default: true, // has content by default
        },
    },

    emits: ['open'],

    data() {
        return {
            isOpen: this.open
        }
    },

    computed: {
        hasContentSlot() {
            return !!this.$slots.content;
        }
    },

    watch: {
        open: function (val) {
            this.isOpen = val
        },
        isOpen: function (val) {
            this.$emit('open', val)
        }
    },

    methods: {
        toggle(e) {
            this.isOpen = !this.isOpen
        }
    }
}
</script>
