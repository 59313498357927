<script setup>

import {useForm, router, Link} from "@inertiajs/vue3";
import {watch} from "vue";
import {debounce, pickBy} from "lodash";
import SimpleBar from 'simplebar-vue'
import Breadcrumbs from "@/Shared/Breadcrumbs.vue";
import SearchFilter from "@/Shared/SearchFilter.vue";
import Swal from '@/swal'
import Table from "@/Shared/Table.vue";
import Pagination from "@/Shared/Pagination.vue";
import TableActionButton from "@/Shared/TableActionButton.vue";
import ColumnSort from "@/Shared/ColumnSort.vue";
import {useToast} from "vue-toastification";
import AccordionItem from "@/Shared/AccordionItem.vue";
import ModalLink from "@/Shared/ModalLink.vue";
import Button from "@/Components/Button.vue";
import SecondaryButton from "@/Components/SecondaryButton.vue";

// Model variables
const modelTitle = 'Chat'
const modelTitlePlural = 'Chats'
const modelTitleLower = 'chat'
const routeBase = 'chat'

/*
 * Table properties
 */
const props = defineProps([
    'filters',
    'create_url',
    'models',
    'variables'
])
const tableForm = useForm({
    search: props.filters.search,
    order: props.filters.order,
    trashed: props.filters.trashed,
})
watch(() => tableForm, () => search)
const search = debounce(function () {
    let query = pickBy(tableForm.data())
    router.get(route(`${routeBase}.index`, Object.keys(query).length ? query : { remember: 'forget' }))
}, 500)

/*
 * Model actions
 */
const destroy = (model) => {
    Swal.fire({
        title: `Delete ${modelTitleLower}`,
        text: 'This action cannot be undone',
        icon: 'warning',
        showCancelButton: true,
    }).then((result) => {
        if (result.isConfirmed) {
            router.delete(route(`${routeBase}.destroy`, model.id), {
                preserveScroll: false,
                onSuccess: () => { router.reload() },
                onError: (error) => { useToast().error(Object.values(error).join(" ")) }
            })
        }
    })
}

const generateDefaults = () => {
    Swal.fire({
        title: 'Generate default models',
        text: 'Generate some recommended default models now?',
        icon: 'info',
        showCancelButton: true,
    }).then((result) => {
        if (result.isConfirmed) {
            router.post(route('chat.defaults.store'), {}, {
                preserveScroll: false,
                onSuccess: () => { router.reload() },
                onError: (error) => { useToast().error(Object.values(error).join(" ")) }
            })
        }
    })
}

const reset = () => {
    tableForm.reset()
}

const getCrumbs = () => {
    return props.variables.section === 'user' ?
        [
            {title: modelTitlePlural, href: null},
        ] : [
            {title: 'Admin', href: null},
            {title: modelTitlePlural, href: null},
        ]
}

</script>

<template>
    <Head :title="modelTitlePlural" />

    <div>
        <PageHeader>{{ modelTitle }}</PageHeader>

        <Breadcrumbs :links="getCrumbs()" />

        <div class="flex flex-row gap-2 flex-wrap content-center justify-between py-6 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <ModalLink v-if="variables.section === 'user'" :href="create_url" class="inline-flex items-center px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray transition ease-in-out duration-150">
                <svg class="w-4 h-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                Create new chat
            </ModalLink>
            <SecondaryButton v-if="variables.section === 'user'" @click="generateDefaults">
                <svg class="w-4 h-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
                Generate defaults
            </SecondaryButton>

            <SearchFilter
                v-model="tableForm.search" class="w-full max-w-md ml-auto"
                @reset="reset"
            />
        </div>

        <template v-if="models.data.length">
            <div class="pb-4">
                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="bg-white shadow-xl sm:rounded-lg">
                        <SimpleBar class="overflow-auto h-auto max-h-[50vh]">
                            <ul role="list" class="inline-block divide-y divide-gray-100 px-6 py-4 min-w-full">
                                <li v-for="(model, idx) in models.data" :key="model.id" class="relative min-w-full">
                                    <AccordionItem type="chat details">
                                        <template #title>
                                            <div class="flex items-center gap-x-4 whitespace-nowrap">
                                                <template v-if="variables.section === 'admin'">
                                                    <ModalLink :href="route('user.edit', model.user.id)" class="ml-2 w-8 h-8 rounded-full overflow-hidden flex items-center border border-2 border-primary-700 hover:border-primary-500">
                                                        <img class="w-full h-auto" :src="model.user.profile_photo_url" alt="User" />
                                                    </ModalLink>
                                                </template>
                                                <InertiaLink :href="model.show_url" class="link">
                                                    {{ model.title }}
                                                </InertiaLink>
                                            </div>
                                        </template>
                                        <template #actions>
                                            <TableActionButton
                                                :actions="[
                                                    {href: model.show_url, title: 'View'},
                                                    {
                                                        title: 'Edit',
                                                        action: () => $emitter.emit('modelAddEdit', {
                                                            url: model.edit_url,
                                                            refresh: true
                                                        })
                                                    },
                                                    {divider: true},
                                                    {title: 'Delete', action: () => destroy(model)},
                                                ]"
                                            />
                                        </template>
                                        <template #meta>
                                            <div class="flex gap-1 items-center">
                                                <div class="">
                                                    <span class="inline-flex items-center gap-x-1.5 bg-gray-50 rounded-full px-2 py-1 text-xs font-bold text-gray-900 ring-1 ring-inset ring-gray-200">
                                                        Messages
                                                        <span class="rounded-full bg-primary-500 text-white px-1 py-0.5 flex items-center">
                                                            {{ model.history_count || 'N/A' }}
                                                        </span>
                                                    </span>
                                                </div>
                                                <span class="inline-flex items-center rounded-full whitespace-nowrap bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                                    {{ model.model }}
                                                </span>
                                                <template v-if="model.type === 'chat' || model.type === 'vision'">
                                                    <span class="inline-flex items-center rounded-full whitespace-nowrap bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                                        {{ model.endpoint }}
                                                    </span>
                                                    <span class="inline-flex items-center rounded-full whitespace-nowrap bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                                        {{ model.role }}
                                                    </span>
                                                    <span class="inline-flex items-center rounded-full whitespace-nowrap bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                                        {{ model.temperature || 'N/A' }}
                                                    </span>
                                                    <span class="inline-flex items-center rounded-full whitespace-nowrap bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                                        {{ model.max_tokens || 'N/A' }}
                                                    </span>
                                                </template>
                                                <template v-else-if="model.type === 'speech'">
                                                    <span class="inline-flex items-center rounded-full whitespace-nowrap bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                                        voice = {{ model.speech_voice || 'N/A' }}
                                                    </span>
                                                    <span class="inline-flex items-center rounded-full whitespace-nowrap bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                                        speed = {{ model.speech_speed || 'N/A' }}
                                                    </span>
                                                </template>
                                                <template v-else>
                                                    <span class="inline-flex items-center rounded-full whitespace-nowrap bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                                        {{ model.size || 'N/A' }}
                                                    </span>
                                                    <span class="inline-flex items-center rounded-full whitespace-nowrap bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                                        n = {{ model.n_items || 'N/A' }}
                                                    </span>
                                                </template>
                                            </div>
                                        </template>
                                        <template #content>
                                            <div class="px-4 sm:px-0">
                                                <h3 class="text-base font-semibold leading-7 text-gray-900">
                                                    Configuration
                                                </h3>
                                                <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                                                    The current configuration details for this chat
                                                </p>
                                            </div>
                                            <div class="mt-6 border-t border-gray-100">
                                                <dl class="divide-y divide-gray-100">
                                                    <template v-if="variables.section === 'admin'">
                                                        <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt class="text-sm font-medium leading-6 text-gray-900">
                                                                User
                                                            </dt>
                                                            <dd class="flex items-center gap-2 mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                <ModalLink :href="route('user.edit', model.user.id)" class="rounded-full overflow-hidden flex items-center border border-2 border-primary-700 hover:border-primary-500">
                                                                    <img class="w-8 h-8" :src="model.user.profile_photo_url" alt="User" />
                                                                </ModalLink>
                                                                {{ model.user.name }}
                                                                ({{ model.user.email }})
                                                            </dd>
                                                        </div>
                                                    </template>

                                                    <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                        <dt class="text-sm font-medium leading-6 text-gray-900">
                                                            Created
                                                        </dt>
                                                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                            {{ $filters.getTimeFormatted(model.created_at) }}
                                                        </dd>
                                                    </div>
                                                    <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                        <dt class="text-sm font-medium leading-6 text-gray-900">
                                                            Model
                                                        </dt>
                                                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                            {{ model.model }}
                                                        </dd>
                                                    </div>
                                                    <template v-if="model.type === 'chat' || model.type === 'vision'">
                                                        <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt class="text-sm font-medium leading-6 text-gray-900">
                                                                Endpoint
                                                            </dt>
                                                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                {{ model.endpoint }}
                                                            </dd>
                                                        </div>
                                                        <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt class="text-sm font-medium leading-6 text-gray-900">
                                                                Role
                                                            </dt>
                                                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                {{ model.role }}
                                                            </dd>
                                                        </div>
                                                        <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt class="text-sm font-medium leading-6 text-gray-900">
                                                                Temperature
                                                            </dt>
                                                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                {{ model.temperature || 'N/A' }}
                                                            </dd>
                                                        </div>
                                                        <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt class="text-sm font-medium leading-6 text-gray-900">
                                                                Max Tokens
                                                            </dt>
                                                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                {{ model.max_tokens || 'N/A' }}
                                                            </dd>
                                                        </div>
                                                    </template>
                                                    <template v-else-if="model.type === 'speech'">
                                                        <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt class="text-sm font-medium leading-6 text-gray-900">
                                                                Voice
                                                            </dt>
                                                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                {{ model.speech_voice || 'N/A' }}
                                                            </dd>
                                                        </div>
                                                        <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt class="text-sm font-medium leading-6 text-gray-900">
                                                                Speed
                                                            </dt>
                                                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                {{ model.speech_speed || 'N/A' }}
                                                            </dd>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt class="text-sm font-medium leading-6 text-gray-900">
                                                                Size
                                                            </dt>
                                                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                {{ model.size }}
                                                            </dd>
                                                        </div>
                                                        <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt class="text-sm font-medium leading-6 text-gray-900">
                                                                Items to generate
                                                            </dt>
                                                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                {{ model.n_items }}
                                                            </dd>
                                                        </div>
                                                    </template>
                                                </dl>
                                            </div>
                                        </template>
                                    </AccordionItem>
                                </li>
                            </ul>
                        </SimpleBar>
                        <Pagination class="pb-4" :links="models.links" />
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <button type="button" @click="generateDefaults" class="group relative flex flex-col items-center justify-center gap-2 w-full rounded-lg border-2 border-dashed border-gray-300 p-12 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span class="ml-12">
                        <svg class="h-12 w-12 text-gray-400 group-hover:text-primary-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z" />
                        </svg>
                    </span>
                    <img class="w-16 h-auto" src="/img/robo-logo.svg" alt="Logo" />
                    <span class="mt-2 block font-semibold text-gray-400 group-hover:text-primary-500">
                        Generate defaults
                    </span>
                </button>
                <p class="mt-3 w-full max-w-md mx-auto text-sm text-gray-300 text-center">
                    No chats created yet, add one to get started. Or use the generate defaults option to generate some recommended default options.
                </p>
            </div>
        </template>
    </div>
</template>
