<template>
    <div v-if="loading" :class="sizeClass" class="relative mx-auto text-secondary-600">
        <svg class="circular absolute top-0 right-0" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="3"
                    stroke-miterlimit="10"
            />
        </svg>
    </div>
</template>
<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        size: {
            types: Number,
            default: 8
        }
    },

    computed: {
        sizeClass: function () {
            return `w-${this.size} h-${this.size}`
        }
    }
}
</script>
