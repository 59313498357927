import Swal from 'sweetalert2'

// Setup Swal
const swal = Swal.mixin({
    customClass: {
        popup: 'bg-white',
        htmlContainer: 'bg-white',
        header: 'bg-white',
        title: 'bg-white text-lg font-bold text-gray-700',
        content: 'bg-white',
        cancelButton: 'mx-2 inline-flex items-center justify-center px-4 py-2 bg-red-600 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-600 disabled:opacity-25 transition ease-in-out duration-150',
        confirmButton: 'mx-2 inline-flex items-center px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray disabled:opacity-25 transition ease-in-out duration-150',
        denyButton: 'mx-2 inline-flex items-center px-4 py-2 bg-white  border border-gray-300 rounded-md font-semibold text-xs text-gray-700 uppercase tracking-widest shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 disabled:opacity-25 transition ease-in-out duration-150',
        actions: 'z-0 gap-2'
    },
    reverseButtons: true,
    buttonsStyling: false
})
export default swal
