<script setup>

import Breadcrumbs from "@/Shared/Breadcrumbs.vue";
import ActionSection from "@/Components/ActionSection.vue";
import Form from "./Partials/Form.vue"

// Model variables
const modelTitle = 'User'
const modelTitleLower = 'user'
const routeBase = 'user'

const props = defineProps([
    'title',
    'description',
    'type',
    'model',
    'variables',
])

const getCrumbs = () => {
    return [
        { title: modelTitle, href: route(`${routeBase}.index`) },
        { title: props.title, href: null },
    ]
}
</script>

<template>
    <Head :title="title" />

    <div>
        <PageHeader>{{ title }}</PageHeader>
        <Breadcrumbs :links="getCrumbs()" />

        <div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">
            <div class="">
                <ActionSection>
                    <template #title>
                        {{ title }}
                    </template>
                    <template #description>
                        <div class="mb-3" v-if="description">
                            {{ description }}
                        </div>
                        <inertia-link v-if="type !== 'POST'" :href="route(`${routeBase}.show`, model.id)" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                            View Details
                            <svg class="ml-2 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                            </svg>
                        </inertia-link>
                    </template>
                    <template #content>
                        <Form
                            :title="title"
                            :description="description"
                            :type="type"
                            :model="model"
                            :variables="variables"
                        />
                    </template>
                </ActionSection>
            </div>
        </div>
    </div>
</template>
