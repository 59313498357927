<template>
    <div class="flex flex-row items-center">
        <button type="button"
                @click="toggle"
                class="mt-1 bg-gray-100 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none">
            <span class="sr-only">Toggle dark mode</span>
            <span aria-hidden="true"
                  :class="{
                      'translate-x-5': mode === 'dark',
                      'translate-x-0': mode === 'light'
                  }"
                  class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200">
                <svg v-if="mode === 'light'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-indigo-500" viewBox="0 0 24 24" fill="currentColor">
                    <path fill-rule="evenodd" d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z" clip-rule="evenodd" />
                </svg>
                <svg v-if="mode === 'dark'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-yellow-600" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clip-rule="evenodd" />
                </svg>
            </span>
        </button>
    </div>
</template>

<script>
export default {
    data () {
        return {
            mode: 'light',
            systemDark: window.matchMedia('(prefers-color-scheme: dark)').matches
        }
    },

    created () {
        const mode = this.$page.props.auth.user?.color_mode || 'light'
        if (mode === 'system') {
            if (this.systemDark) {
                this.mode = 'dark'
            } // Else light, which is set by default...
        } else {
            this.mode = mode
        }

        if (mode === 'light') {
            if (document.documentElement.classList.contains('dark')) {
                document.documentElement.classList.remove('dark')
            }
        }
    },

    methods: {
        toggle () {
            // Determine the new mode selected
            // We'll update the DOM as well to have color take effect immediately
            // NOTE: this is taken care of in `app.js`, however, it doesn't take effect until user is returned from response
            if (this.mode === 'light') {
                this.mode = 'dark'
                if (!document.documentElement.classList.contains('dark')) {
                    document.documentElement.classList.add('dark')
                }
            } else {
                this.mode = 'light'
                if (document.documentElement.classList.contains('dark')) {
                    document.documentElement.classList.remove('dark')
                }
            }

            // Update user preferences in DB
            // Determine whether to set mode, or set to system default
            if ((this.systemDark && this.mode === 'dark') || (!this.systemDark && this.mode === 'light')) {
                // Set mode to "system"
                axios.post(route('user.color-preference'), { color_mode: 'system' })
                    .then(response => {
                        localStorage.removeItem('theme')
                        this.$toast().success(response.data.message)
                    })
            } else {
                // Set mode to dark/light
                axios.post(route('user.color-preference'), { color_mode: this.mode })
                    .then(response => {
                        localStorage.setItem('theme', this.mode)
                        this.$toast().success(response.data.message)
                    })
            }
        }
    }
}
</script>
