<template>
    <div>
        <page-header>Error</page-header>

        <breadcrumbs :links="[
            { title: 'Error', href: null },
        ]" />

        <div class="py-6 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="bg-white  overflow-hidden shadow-xl sm:rounded-lg p-4">
                <p class="text-2xl mb-3">
                    Oops! Looks like something went wrong...
                </p>
                <alert :message="$page.props.flash.error || 'Unknown'" />
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from '@/Shared/Breadcrumbs.vue'
import Alert from '@/Components/Alert.vue'

export default {
    components: {
        Alert,
        Breadcrumbs
    }
}
</script>
