<template>
    <div>
        <div class="bg-gradient-to-r rounded-md border-2"
             :class="{
                'from-yellow-500 to-yellow-400 border-yellow-500': type === 'warning',
                'from-orange-700 to-red-500 border-orange-700': type === 'danger',
                'from-indigo-700 to-blue-500 border-indigo-700': type === 'info',
                'from-indigo-700 to-green-400 border-indigo-700': type === 'success',
             }"
        >
            <div class="mx-auto py-2 px-3">
                <div class="flex items-center justify-between flex-wrap">
                    <div class="w-0 flex-1 flex items-center min-w-0">
                        <span
                            class="flex bg-opacity-25 p-2 rounded-lg mr-2"
                            :class="{'bg-indigo-500 ': type === 'info','bg-indigo-500': type === 'success', 'bg-yellow-300': type === 'warning', 'bg-orange-500': type === 'danger'}"
                        >
                            <svg v-if="type === 'warning' || type === 'danger'"
                                 class="h-5 w-5"
                                 :class="{
                                    'text-yellow-600': type === 'warning',
                                    'text-orange-800': type === 'danger',
                                 }" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true">
                                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                            </svg>
                            <svg v-if="type ==='info'" class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <svg v-if="type ==='success'" class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </span>
                        <div class="ml-1 font-medium text-sm text-white">
                            <p v-if="title" class="font-bold truncate">
                                {{ title }}
                            </p>
                            <p v-if="message">
                                {{ message }}
                            </p>
                            <slot></slot>
                        </div>
                    </div>
                    <div v-if="action" class="flex-shrink-0 sm:ml-3">
                        <button
                            type="button"
                            @click="action.action"
                            class="-mr-1 text-sm font-medium text-white flex p-2 rounded-md focus:outline-none sm:-mr-2 transition ease-in-out duration-150"
                            :class="{
                                'hover:bg-yellow-300 focus:bg-yellow-300': type === 'warning',
                                'hover:bg-red-300 focus:bg-red-300': type === 'danger',
                                'hover:bg-blue-300 focus:bg-blue-300': type === 'info',
                                'hover:bg-green-300 focus:bg-green-300': type === 'success',
                            }"
                        >
                            {{ action.label }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false
        },
        message: {
            type: String,
            required: false
        },
        type: {
            default: 'warning'
        },
        action: {
            default: null
        }
    },
}
</script>
