<template>
    <div>
        <jet-banner />

        <div class="h-screen flex overflow-y-hidden">

            <div class="flex flex-col w-0 flex-1 overflow-auto">
                <nav
                    class="bg-white border-b border-gray-100">
                    <!-- Primary Navigation Menu -->
                    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div class="flex justify-between items-center h-16">
                            <div class="flex">
                                <!-- Logo -->
                                <div class="flex-shrink-0 flex items-center">
                                    <inertia-link :href="route('dashboard')">
                                        <jet-application-mark
                                            class="block h-9 w-auto" />
                                    </inertia-link>
                                </div>
                            </div>
                            <div v-if="!$page.props.auth.user" class="flex items-center gap-2">
                                <template v-if="route().current() !== 'home'">
                                    <div class="">
                                        <jet-button @click="login">
                                            Login
                                        </jet-button>
                                    </div>
                                    <div v-if="route().check('register')" class="">
                                        <jet-secondary-button @click="register">
                                            Register
                                        </jet-secondary-button>
                                    </div>
                                </template>
                            </div>
                            <div v-else class="inline-block ml-3 relative">
                                <!-- Settings Dropdown -->
                                <div class="ml-3 relative">
                                    <JetDropdown align="right" width="48">
                                        <template #trigger>
                                            <button v-if="$page.props.jetstream.managesProfilePhotos" class="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition">
                                                <img class="h-8 w-8 rounded-full object-cover" :src="$page.props.auth.user.profile_photo_url" :alt="$page.props.auth.user.name">
                                            </button>

                                            <span v-else class="inline-flex rounded-md">
                                                <button type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none transition">
                                                    {{ $page.props.auth.user.name }}

                                                    <svg
                                                        class="ml-2 -mr-0.5 h-4 w-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                    >
                                                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                                                    </svg>
                                                </button>
                                            </span>
                                        </template>

                                        <template #content>
                                            <!-- Authentication -->
                                            <jet-dropdown-link as="button" @click.native="logout">
                                                Log Out
                                            </jet-dropdown-link>
                                        </template>
                                    </JetDropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>

                <!-- Page Heading -->
                <header class="bg-white  shadow lg:hidden" v-if="$slots.header">
                    <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <slot name="header"></slot>
                    </div>
                </header>

                <!-- Page Content -->
                <main class="pb-16 flex-grow">
                    <slot></slot>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import JetApplicationMark from '@/Components/ApplicationMark.vue'
import JetButton from '@/Components/Button.vue'
import JetSecondaryButton from '@/Components/SecondaryButton.vue'
import JetDangerButton from '@/Components/DangerButton.vue'
import JetBanner from '@/Components/Banner.vue'
import JetDropdown from '@/Components/Dropdown.vue'
import JetDropdownLink from '@/Components/DropdownLink.vue'
import JetNavLink from '@/Components/NavLink.vue'
import JetResponsiveNavLink from '@/Components/ResponsiveNavLink.vue'

export default {
    components: {
        JetApplicationMark,
        JetBanner,
        JetSecondaryButton,
        JetDangerButton,
        JetDropdown,
        JetDropdownLink,
        JetNavLink,
        JetButton,
        JetResponsiveNavLink,
    },

    data () {
        return {
            showingNavigationDropdown: false,
        }
    },

    watch: {
        '$page.props.flash': function ({ error, success }) {
            if (error) {
                this.$toast().error(error)
            }
            if (success) {
                this.$toast().success(success)
            }
        },
    },

    methods: {
        logout () {
            this.$inertia.post(route('logout'))
        },
        register () {
            this.$inertia.get(route('register'))
        },
        login () {
            this.$inertia.get(route('login'))
        }
    }
}
</script>
