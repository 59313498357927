<template>
    <svg id="logo" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 4000 4000" style="enable-background:new 0 0 4000 4000;" xml:space="preserve">
        <g>
            <g>
                <g>
                    <g>
                        <g>
                            <path class="st0" d="M2032.68,881.64c303.35,0,541.32,50.62,541.32,50.62l-70.69-172.96c-19.76-48.34-60.47-85.22-110.74-99.37
                                c-70.73-19.9-188.65-42.45-359.89-42.45s-289.16,22.55-359.89,42.45c-50.27,14.14-90.98,51.03-110.74,99.37l-70.69,172.96
                                C1491.37,932.26,1729.34,881.64,2032.68,881.64z"/>
                        </g>
                        <g>
                            <path class="st1" d="M2574,971.63c-2.71,0-5.46-0.28-8.17-0.86c-2.34-0.5-237.87-49.75-533.13-49.75
                                c-295.27,0-530.79,49.26-533.13,49.75c-14.27,3.05-29.04-2.08-38.39-13.27s-11.75-26.63-6.23-40.13l70.68-172.96
                                c24.63-60.29,74.4-104.89,136.51-122.37c71.22-20.03,193.3-43.91,370.57-43.91c177.25,0,299.34,23.88,370.55,43.91
                                c62.12,17.48,111.88,62.08,136.51,122.37l70.69,172.96c5.52,13.5,3.12,28.94-6.23,40.13
                                C2596.65,966.56,2585.52,971.63,2574,971.63z M2032.69,656.86c-168.27,0-282.8,22.28-349.24,40.97
                                c-38.02,10.7-69.78,39.25-84.95,76.36l-43.52,106.5c93.55-15.27,269.61-38.41,477.72-38.41c208.1,0,384.17,23.14,477.71,38.41
                                l-43.52-106.49c-15.18-37.12-46.94-65.66-84.96-76.36C2315.48,679.14,2200.96,656.86,2032.69,656.86z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <path class="st2" d="M282.89,1853.56l63.3-601.39c8.13-77.2,44.51-148.66,102.15-200.65l0,0c28.85-26.02,74.98-7,77.1,31.79
                                    l42.17,770.24H282.89z"/>
                            </g>
                            <g class="st3">
                                <path class="st1" d="M523.59,1049.46c-2.67-48.78-75.25-46.79-75.25,2.06v0c-4.19,3.78-8.23,7.68-12.18,11.66l23.92,437.01
                                    c4.95,90.37-67,166.36-157.51,166.36h0l-19.68,187h284.72L523.59,1049.46z"/>
                            </g>
                            <g>
                                <path class="st1" d="M567.61,1892.92H282.89c-11.16,0-21.8-4.74-29.26-13.03c-7.47-8.29-11.07-19.37-9.89-30.46l63.31-601.39
                                    c9.15-86.99,49.96-167.16,114.93-225.76c24.71-22.28,59.19-28.25,89.94-15.57c30.77,12.69,51.01,41.21,52.83,74.45
                                    l42.17,770.24c0.59,10.79-3.29,21.36-10.72,29.22C588.76,1888.48,578.42,1892.92,567.61,1892.92z M326.62,1814.18h199.41
                                    l-39.9-728.72c-0.05-1.07-0.23-4.31-4.23-5.95c-3.98-1.64-6.4,0.53-7.19,1.24c-50.51,45.56-82.24,107.9-89.36,175.54
                                    L326.62,1814.18z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path class="st0" d="M623.5,2813.14c-52.91,8.04-105.06,11.02-153.72,10.92c-157.78-0.32-287.74-123.2-300.52-280.45
                                    c-17.94-220.72-11.43-407.86-0.01-541.92c12.12-142.29,121.78-256.85,263.38-275.39c41.19-5.39,79.24-8.86,111.13-11.09
                                    c52.38-3.66,97.77,35.83,101.6,88.19l65.78,899.83C715.07,2757.1,676.9,2805.02,623.5,2813.14z"/>
                            </g>
                            <g class="st3">
                                <path class="st1" d="M711.13,2703.23l-19.44-265.98c-0.94,49.86-37.67,92.68-87.88,100.31
                                    c-52.91,8.04-105.06,11.02-153.72,10.92c-139.66-0.29-257.47-96.63-291.55-227.82c1.05,69.46,4.3,144.01,10.72,222.94
                                    c12.78,157.26,142.74,280.13,300.52,280.45c48.66,0.1,100.81-2.88,153.72-10.92C676.9,2805.02,715.07,2757.1,711.13,2703.23z"
                                />
                            </g>
                            <g>
                                <path class="st1" d="M472.09,2863.43c-0.8,0-1.6,0-2.4,0c-176.07-0.36-325.28-139.44-339.67-316.64
                                    c-15.13-186.21-15.13-370.73,0-548.45c13.59-159.46,138.7-290.29,297.5-311.09c36.94-4.84,75.13-8.64,113.51-11.33
                                    c73.82-5.01,138.2,50.74,143.6,124.6l65.78,899.84c5.4,73.93-47.74,140.56-121,151.69
                                    C579.76,2859.6,526.83,2863.43,472.09,2863.43z M550.41,1754.34c-1.29,0-2.59,0.05-3.9,0.14
                                    c-36.81,2.57-73.4,6.22-108.77,10.85c-122.38,16.02-218.81,116.83-229.27,239.7c-14.77,173.39-14.76,353.52,0.02,535.39
                                    c11.11,136.7,125.91,244,261.36,244.27c0.74,0,1.5,0,2.25,0c50.79,0,99.72-3.52,145.48-10.48l0,0
                                    c32.87-4.99,56.72-34.91,54.29-68.1l-65.78-899.84C603.94,1776.89,579.36,1754.34,550.41,1754.34z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <path class="st2" d="M3782.48,1853.56l-63.3-601.39c-8.13-77.2-44.51-148.66-102.15-200.65l0,0
                                    c-28.85-26.02-74.98-7-77.1,31.79l-42.17,770.24H3782.48z"/>
                            </g>
                            <g class="st3">
                                <path class="st1" d="M3541.78,1049.46c2.67-48.78,75.25-46.79,75.25,2.06v0c4.19,3.78,8.23,7.68,12.18,11.66l-23.92,437.01
                                    c-4.95,90.37,67,166.36,157.51,166.36h0l19.68,187h-284.72L3541.78,1049.46z"/>
                            </g>
                            <g>
                                <path class="st1" d="M3782.48,1892.92h-284.72c-10.81,0-21.16-4.45-28.59-12.3s-11.31-18.42-10.72-29.22l42.17-770.24
                                    c1.82-33.24,22.07-61.76,52.83-74.45c30.77-12.69,65.23-6.72,89.94,15.57c64.96,58.59,105.78,138.76,114.94,225.76
                                    l63.3,601.39c1.17,11.1-2.42,22.17-9.89,30.46C3804.27,1888.19,3793.63,1892.92,3782.48,1892.92z M3539.34,1814.18h199.41
                                    l-58.72-557.9c-7.13-67.64-38.87-129.98-89.37-175.53c-0.8-0.72-3.21-2.89-7.19-1.25c-3.99,1.64-4.17,4.89-4.23,5.95
                                    L3539.34,1814.18z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path class="st0" d="M3441.87,2813.14c52.91,8.04,105.06,11.02,153.72,10.92c157.78-0.32,287.74-123.2,300.52-280.45
                                    c17.94-220.72,11.43-407.86,0.01-541.92c-12.12-142.29-121.78-256.85-263.38-275.39c-41.19-5.39-79.24-8.86-111.13-11.09
                                    c-52.38-3.66-97.77,35.83-101.6,88.19l-65.78,899.83C3350.3,2757.1,3388.47,2805.02,3441.87,2813.14z"/>
                            </g>
                            <g class="st3">
                                <path class="st1" d="M3354.24,2703.23l19.44-265.98c0.94,49.86,37.67,92.68,87.88,100.31
                                    c52.91,8.04,105.06,11.02,153.72,10.92c139.66-0.29,257.47-96.63,291.55-227.82c-1.05,69.46-4.3,144.01-10.72,222.94
                                    c-12.78,157.26-142.74,280.13-300.52,280.45c-48.66,0.1-100.81-2.88-153.72-10.92
                                    C3388.47,2805.02,3350.3,2757.1,3354.24,2703.23z"/>
                            </g>
                            <g>
                                <path class="st1" d="M3593.27,2863.43c-54.74,0-107.65-3.83-157.32-11.37c-73.25-11.13-126.39-77.76-120.99-151.69
                                    l65.78-899.83c5.4-73.85,69.55-129.79,143.61-124.6c38.37,2.68,76.55,6.49,113.5,11.32
                                    c158.79,20.79,283.91,151.62,297.49,311.09c15.14,177.65,15.14,362.17,0,548.45c-14.39,177.19-163.6,316.27-339.67,316.64
                                    C3594.87,2863.43,3594.07,2863.43,3593.27,2863.43z M3514.97,1754.34c-28.96,0-53.54,22.55-55.69,51.93l-65.78,899.83
                                    c-2.43,33.2,21.42,63.12,54.29,68.11l0,0c45.76,6.96,94.69,10.48,145.48,10.48c0.75,0,1.5,0,2.25,0
                                    c135.46-0.28,250.26-107.57,261.36-244.27c14.79-181.94,14.8-362.07,0.02-535.39c-10.46-122.87-106.88-223.68-229.26-239.7
                                    c-35.37-4.63-71.97-8.28-108.77-10.85C3517.57,1754.39,3516.26,1754.34,3514.97,1754.34z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path class="st0" d="M2032.68,3413.18c1647.86,0,1585.29-1157.86,1585.29-1157.86c0-1518.5-1585.29-1436.25-1585.29-1436.25
                                S447.4,736.82,447.4,2255.33C447.4,2255.33,390.45,3413.18,2032.68,3413.18z"/>
                        </g>
                        <g class="st3">
                            <path class="st1" d="M3613.91,2125.94c-27.85,278.49-226.64,1060.87-1581.23,1060.87c-1354.42,0-1553.05-787.55-1581.07-1063.6
                                c-2.76,42.65-4.22,86.64-4.22,132.11c0,0-56.94,1157.86,1585.29,1157.86c1647.86,0,1585.29-1157.86,1585.29-1157.86
                                C3617.97,2210.82,3616.56,2167.74,3613.91,2125.94z"/>
                        </g>
                        <g>
                            <g>
                                <path class="st1" d="M1803.36,2876.48c151.4-29.8,307.26-29.8,458.66,0c1147.62,225.91,1127.46-536.57,1127.46-536.57
                                    c0-1055.35-1356.79-993.28-1356.79-993.28S675.9,1284.56,675.9,2339.92C675.9,2339.92,655.74,3102.39,1803.36,2876.48z"/>
                            </g>
                            <g>
                                <path class="st1" d="M3381.54,2205.7c-33.65,190.71-209.1,633.47-1119.52,454.26c-151.4-29.8-307.26-29.8-458.66,0
                                    C892.94,2839.17,717.48,2396.41,683.83,2205.7c-5.13,42.63-7.93,87.27-7.93,134.22c0,0-20.16,762.48,1127.46,536.57
                                    c151.4-29.8,307.26-29.8,458.66,0c1147.62,225.91,1127.46-536.57,1127.46-536.57
                                    C3389.47,2292.97,3386.67,2248.33,3381.54,2205.7z"/>
                            </g>
                            <g class="st4">
                                <path class="st0" d="M711.43,2417.72c0-1027.71,1321.25-967.26,1321.25-967.26s1321.25-60.45,1321.25,967.26
                                    c0,0,2.21,85.72-38.61,190.25c77.52-137.22,74.15-268.06,74.15-268.06c0-1055.36-1356.79-993.28-1356.79-993.28
                                    S675.9,1284.56,675.9,2339.92c0,0-3.37,130.84,74.15,268.06C709.22,2503.44,711.43,2417.72,711.43,2417.72z"/>
                            </g>
                            <g>
                                <path class="st1" d="M1413.7,2957.86c-241.21,0-431.14-57.63-567.17-172.53c-211.44-178.62-210.35-428.51-210-445.85
                                    c0.1-277.47,91.4-505.35,271.39-677.29c395.29-377.62,1076.06-356.91,1124.76-354.95c48.67-2.01,729.46-22.67,1124.75,354.95
                                    c179.99,171.94,271.3,399.83,271.39,677.29c0.35,17.34,1.45,267.23-210,445.85c-203.63,172.01-528.09,215.68-964.43,129.78
                                    c-147.13-28.96-296.33-28.95-443.45,0l0,0C1666.11,2943.63,1533.56,2957.86,1413.7,2957.86z M2032.69,2814.65
                                    c79.16,0,158.32,7.74,236.93,23.21c410.95,80.89,712.88,43.26,897.43-111.85c185.79-156.16,183.11-382.87,183.06-385.14
                                    c-0.01-0.28-0.01-0.66-0.01-0.94c0-255.21-83.05-464.01-246.83-620.59c-376.74-360.16-1061.89-333.66-1068.8-333.36
                                    c-1.19,0.05-2.38,0.05-3.58,0c-6.83-0.31-692.07-26.8-1068.81,333.36c-163.79,156.58-246.83,365.37-246.83,620.59
                                    c0,0.35,0,0.7-0.01,1.04c-0.05,2.18-2.73,228.89,183.06,385.04c184.55,155.11,486.51,192.75,897.44,111.85l0,0
                                    C1874.36,2822.38,1953.52,2814.65,2032.69,2814.65z"/>
                            </g>
                        </g>
                        <g>
                            <path class="st2" d="M1212.01,2365.31c-7.2,0-14.53-1.23-21.69-3.83c-33.03-11.98-50.1-48.48-38.12-81.51
                                c34.8-95.95,146.43-170.09,271.47-180.27c127.95-10.42,237.17,48.11,300.16,160.55c17.17,30.66,6.23,69.44-24.43,86.61
                                c-30.64,17.16-69.42,6.26-86.61-24.42c-51.48-91.92-134.13-99.6-178.78-95.91c-80.87,6.59-147.15,55.39-162.18,96.82
                                C1262.45,2349.23,1238.03,2365.31,1212.01,2365.31z"/>
                        </g>
                        <g>
                            <path class="st2" d="M2853.35,2365.31c-26.01,0-50.43-16.08-59.82-41.95c-15.02-41.43-81.3-90.23-162.17-96.82
                                c-44.67-3.69-127.31,3.98-178.79,95.91c-17.18,30.67-55.95,41.58-86.61,24.42c-30.66-17.17-41.6-55.95-24.43-86.61
                                c62.99-112.45,172.32-170.98,300.16-160.55c125.04,10.19,236.66,84.33,271.46,180.27c11.98,33.04-5.09,69.53-38.13,81.51
                                C2867.88,2364.08,2860.56,2365.31,2853.35,2365.31z"/>
                        </g>
                        <g>
                            <path class="st1" d="M2032.69,3452.55c-595.26,0-1039.4-149.49-1320.09-444.32c-316.23-332.16-305.76-725.64-304.56-753.79
                                c0.18-447.06,135.67-802.32,402.72-1055.87c249.38-236.78,568.93-338.03,793.09-381.26c231.16-44.6,407-38.63,428.84-37.65
                                c21.82-0.98,197.67-6.96,428.84,37.65c224.15,43.24,543.71,144.48,793.08,381.26c267.03,253.54,402.53,608.74,402.72,1055.77
                                c1.35,28.82,13.32,420.9-300.87,752.53C3076.28,3302.6,2630.89,3452.55,2032.69,3452.55z M1994.5,857.78
                                c-150.53,0-737.32,25.16-1129.93,398.27c-250.69,238.25-377.8,574.45-377.8,999.27l-0.05,1.93
                                c-0.17,3.76-14.23,385.48,283.93,697.76c265.32,277.89,689.94,418.79,1262.04,418.79c575.74,0,1001.89-141.68,1266.62-421.1
                                c296.4-312.84,279.56-691.48,279.35-695.27l-0.05-2.12c0-424.82-127.11-761.03-377.8-999.28
                                c-451.23-428.83-1158.98-397.99-1166.09-397.66l-2.02,0.1l-2.04-0.1C2029.73,858.35,2016.97,857.78,1994.5,857.78z"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
