<template>
    <div class="flex flex-row items-center">
        <button type="button"
                :disabled="disabled"
                @click="toggle"
                :aria-pressed="toggled"
                :class="{
                    'bg-primary-400': toggled,
                    'bg-gray-300': !toggled,
                    'cursor-not-allowed': disabled
                }"
                class="mt-1 bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50">
            <span class="sr-only">{{ name || 'Toggle' }}</span>
            <span aria-hidden="true"
                  :class="{
                      'translate-x-5': toggled,
                      'translate-x-0': !toggled
                  }"
                  class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white  shadow transform ring-0 transition ease-in-out duration-200"></span>
        </button>
        <p v-if="name" class="text-xs font-medium text-gray-500 ml-2 mt-1">
            {{ name }}
        </p>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: ['modelValue', 'value', 'name', 'disabled'],

    emits: ['update:modelValue', 'toggled'],

    data () {
        return {
            toggled: this.modelValue || this.value
        }
    },

    watch: {
        value: function (val) {
            this.toggled = val
        },
        modelValue: function (val) {
            this.toggled = val
        }
    },

    methods: {
        toggle () {
            this.toggled = !this.toggled
            this.$emit('toggled')
            this.$emit('update:modelValue', this.toggled)
        },
        focus() {
            this.$refs.input.focus()
        }
    }
}
</script>
