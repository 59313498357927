<template>
    <div class="">
        <div class="flex flex-row items-center gap-x-2">
            <p v-if="offName" :class="[!toggled ? 'font-bold text-gray-700' : 'font-medium text-gray-500', 'text-xs']">
                {{ offName }}
            </p>
            <button type="button"
                    :disabled="disabled"
                    @click="toggle"
                    :aria-pressed="toggled"
                    :class="{
                        'bg-gray-300': !toggled,
                        'cursor-wait': disabled,
                        'cursor-not-allowed': disabled
                    }"
                    class="bg-green-400 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50">
                <span class="sr-only">Toggle {{ onName }}/{{ offName }}</span>
                <span aria-hidden="true"
                      :class="{
                      'translate-x-5': toggled,
                      'translate-x-0': !toggled
                  }"
                      class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white  shadow transform ring-0 transition ease-in-out duration-200"></span>
            </button>
            <p v-if="onName" :class="[toggled ? 'font-bold text-gray-700' : 'font-medium text-gray-500', 'text-xs']">
                {{ onName }}
            </p>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'modelValue',
        'value',
        'onName',
        'offName',
        'disabled'
    ],

    emits: ['update:modelValue', 'toggled'],

    data () {
        return {
            toggled: this.modelValue || this.value
        }
    },

    watch: {
        'modelValue': function (val) {
            this.toggled = val
        }
    },

    methods: {
        toggle () {
            this.toggled = !this.toggled
            this.$emit('toggled')
            this.$emit('update:modelValue', this.toggled)
        },
        focus() {
            this.$refs.input.focus()
        }
    }
}
</script>
