<script setup>

import {
    useForm,
    // router
} from "@inertiajs/vue3";
import {onMounted, ref, watch} from "vue";
import DangerButton from "@/Components/DangerButton.vue";
//import SecondaryButton from "@/Components/SecondaryButton.vue";
import Button from "@/Components/Button.vue";
import ActionMessage from "@/Components/ActionMessage.vue";
import InputLabel from "@/Components/InputLabel.vue";
import swal from '@/swal'
import {useToast} from "vue-toastification";
import InputError from "@/Components/InputError.vue";
import TextInput from '@/Components/TextInput.vue';
import ModalFormSection from "@/Components/ModalFormSection.vue";
import InputInfoText from "@/Components/InputInfoText.vue";
import ToggleSwitch from "@/Components/ToggleSwitch.vue";

// Model variables
const modelTitle = 'User'
const modelTitleLower = 'user'
const routeBase = 'user'

const props = defineProps([
    'title',
    'description',
    'type',
    'model',
    'variables',

    // Form specific props for using CRUD-modal
    'defer_submit',
    'submitting',
    'errors'
])
const emit = defineEmits(['success', 'submit', 'destroy'])

const isReadyToSubmit = ref(true)

const form = useForm({
    name: '',
    email: '',
    is_admin: false,
})

onMounted(() => {
    // If model passed, pre-populate the form values
    if (props.model) {
        for (let key of Object.keys(form.data())) {
            if (props.model.hasOwnProperty(key)) {
                form[key] = props.model[key]
            }
        }
    }
})

const destroy = () => {
    swal.fire({
        title: `Delete ${modelTitleLower}`,
        text: 'This action cannot be undone',
        icon: 'warning',
        showCancelButton: true,
    }).then((result) => {
        if (result.isConfirmed) {
            const url = route(`${routeBase}.destroy`, props.model.id)
            if (props.defer_submit === true) {
                emit('destroy', {
                    method: 'delete',
                    url,
                    data: form.data()
                })
                return
            }
            form.delete(url, {
                preserveScroll: false,
                onSuccess: () => { emit('success') },
                onError: (error) => { useToast().error(Object.values(error).join(" ")) }
            })
        }
    })
}

const submit = () => {
    const method = props.type === 'POST' ? 'post' : 'patch'
    const url = props.type === 'POST'
        ? route(`${routeBase}.store`)
        : route(`${routeBase}.update`, props.model.id)

    if (props.defer_submit === true) {
        emit('submit', {
            method,
            url,
            data: form.data()
        })
        return
    }

    form[method](url, {
        preserveScroll: false,
        onSuccess: () => { emit('success') },
        onError: (error) => { useToast().error(Object.values(error).join(" ")) }
    })
}

</script>

<template>
    <div class="w-full h-full">
        <ModalFormSection @submitted="submit">
            <template #form>
                <div class="col-span-6">
                    <div class="mb-3">
                        <ToggleSwitch
                            v-model="form.is_admin"
                            name="Admin"
                        />
                    </div>
                    <div class="mb-3">
                        <InputLabel for="name" value="Name" :required="true" />
                        <TextInput
                            id="name"
                            type="text"
                            v-model="form.name"
                            class="mt-1 block w-full"
                        />
                        <InputError :message="form.errors.name" class="mt-2"/>
                    </div>
                    <div class="mb-3">
                        <InputLabel for="email" value="Email" :required="true" />
                        <TextInput
                            id="email"
                            type="email"
                            v-model="form.email"
                            class="mt-1 block w-full"
                        />
                        <InputError :message="form.errors.email" class="mt-2"/>
                    </div>
                </div>
            </template>

            <template #actions>
                <DangerButton
                    v-if="type !== 'POST'"
                    type="button"
                    @click.native="destroy"
                    class="mr-3"
                    :class="{ 'opacity-25': form.processing || !isReadyToSubmit }"
                    :disabled="form.processing || !isReadyToSubmit"
                >
                    Delete
                </DangerButton>
                <ActionMessage :on="form.recentlySuccessful" class="ml-auto mr-3">
                    {{ type === 'POST' ? 'Created.' : 'Updated.'  }}
                </ActionMessage>
                <Button
                    :class="{ 'opacity-25': form.processing || submitting || !isReadyToSubmit }"
                    :disabled="form.processing || submitting || !isReadyToSubmit"
                >
                    {{ type === 'POST' ? 'Create' : 'Update' }}
                </Button>
            </template>
        </ModalFormSection>
    </div>
</template>
