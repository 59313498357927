<template>
    <div class="text-left">
        <button class="py-1 inline-flex items-center text-xs font-medium text-gray-500 uppercase focus:outline-none" type="button" @click="updateSort()">
            {{ name }}
            <slot></slot>
            <template v-if="!getSort()">
                <svg class="ml-2 -mr-1 h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                          d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                          clip-rule="evenodd" />
                </svg>
            </template>
            <template v-if="getSort() === 'asc'">
                <svg class="ml-2 -mr-1 h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clip-rule="evenodd" />
                </svg>
            </template>
            <template v-if="getSort() === 'desc'">
                <svg class="ml-2 -mr-1 h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd" />
                </svg>
            </template>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        name: String,
        modelValue: String,
        column: String,
    },

    emits: ['update:modelValue'],

    methods: {
        getSort () {
            return this.modelValue ? JSON.parse(this.modelValue)[this.column] : null
        },

        updateSort () {
            // Setup the column sort object
            let col = {}
            col[this.column] = null

            // Based on direction, toggle it!
            switch (this.getSort()) {
                case undefined:
                case null:
                    col[this.column] = 'asc'
                    this.$emit('update:modelValue', JSON.stringify(col))
                    break
                case 'asc':
                    col[this.column] = 'desc'
                    this.$emit('update:modelValue', JSON.stringify(col))
                    break
                case 'desc':
                    this.$emit('update:modelValue', null)
                    break
            }
        }
    }
}
</script>
