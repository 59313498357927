<template>
    <button type="button" @click="onClick">
        <slot/>
    </button>
</template>

<script>

export default {
    props: {
        href: {
            type: String,
            required: true
        },
        refresh: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        onClick() {
            this.$emitter.emit('modelAddEdit', {
                url: this.href,
                refresh: this.refresh
            })
        }
    }
}
</script>
