<template>
    <select class="mt-1 h-10 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-200 focus:ring-opacity-50 sm:text-sm rounded-md" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" ref="input">
        <slot></slot>
        <option v-if="options"
                v-for="option in options"
                :value="option"
                :selected="option === modelValue">
            <span v-if="translate">
                {{ $filters.translate(option) }}
            </span>
            <span v-else>
                {{ option }}
            </span>
        </option>
    </select>
</template>

<script>
export default {
    props: ['modelValue', 'options', 'translate'],

    emits: ['update:modelValue'],

    methods: {
        focus() {
            this.$refs.input.focus()
        }
    }
}
</script>
