<template>
    <teleport to="body">
        <transition leave-active-class="duration-200">
            <div v-show="show" class="fixed inset-0 overflow-y-auto px-4 py-6 sm:px-0 z-50" scroll-region>
                <transition
                    enter-active-class="ease-out duration-300"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="ease-in duration-200"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <div v-show="show" class="fixed inset-0 transform transition-all">
                        <div class="absolute inset-0 bg-gray-500 opacity-75" />
                    </div>
                </transition>

                <transition enter-active-class="transform transition ease-in-out duration-500"
                            enter-from-class="translate-x-full"
                            enter-to-class="translate-x-0"
                            leave-active-class="transform transition ease-in-out duration-500"
                            leave-from-class="translate-x-0"
                            leave-to-class="translate-x-full">
                    <div v-show="show"
                         class="fixed inset-0 overflow-hidden"
                         aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
                        <div class="absolute inset-0 overflow-hidden">
                            <div class="fixed inset-y-0 right-0 pl-10 flex sm:pl-16">
                                <div class="w-screen max-w-md">

                                    <div class="fixed z-10 inset-0 transform transition-all" @click="close">
                                        <div class="absolute" />
                                    </div>

                                    <div class="relative z-20 h-full flex flex-col bg-white shadow-xl overflow-y-auto">
                                        <div class="p-6">
                                            <div class="flex items-start justify-between">
                                                <h2 class="text-lg font-medium text-gray-900 " id="slide-over-title">
                                                    Notifications
                                                </h2>
                                                <div class="ml-3 h-7 flex items-center">
                                                    <button @click="close" type="button" class="bg-white  rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-primary-500">
                                                        <span class="sr-only">Close panel</span>
                                                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="border-b border-gray-200 ">
                                            <div class="px-6">
                                                <nav class="-mb-px flex space-x-6">
                                                    <a v-for="(tab, index) in tabs"
                                                       href="#"
                                                       @click.native="changeTab(index)"
                                                       class="font-medium text-sm whitespace-nowrap pb-4 px-1 border-b-2"
                                                       :class="{
                                                          'border-primary-500 text-primary-600': index === activeTab,
                                                          'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': index !== activeTab,
                                                       }">
                                                        {{ tab }}
                                                    </a>
                                                </nav>
                                            </div>
                                        </div>
                                        <simple-bar v-if="activeTab === 0" class="h-0 flex-1 flex flex-col overflow-y-auto overflow-x-hidden">
                                            <ul class="flex-1 divide-y divide-gray-200 overflow-hidden">

                                                <li v-if="!unread.length">
                                                    <div class="group flex justify-between items-center p-4">
                                                        <span class="-m-1 p-1 block">
                                                            <div class="flex-1 flex items-center min-w-0 relative">
                                                                <div class="truncate">
                                                                    <div class="flex gap-2 pb-2 pl-4">
                                                                        <div class="rounded-full shadow w-10 h-10 overflow-hidden flex items-center justify-center text-gray-600">
                                                                            <svg class="h-6 w-6 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                                                <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                                                            </svg>
                                                                        </div>
                                                                        <div>
                                                                            <p class="flex items-center text-sm font-medium text-gray-900  truncate">
                                                                                <span class="mt-1 mr-1">
                                                                                    No unread notifications
                                                                                </span>
                                                                            </p>
                                                                            <p class="text-sm text-gray-500 truncate">
                                                                                you're all caught up!
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </li>

                                                <li v-for="notification in unread" class="px-6 py-5 relative">
                                                    <div class="group">
                                                        <span class="-m-1 p-1 block">
                                                            <div class="flex-1 flex items-center min-w-0 relative">
                                                                <div class="ml-4">
                                                                    <p class="font-medium text-gray-900">
                                                                        {{ notification.data.title }}
                                                                    </p>
                                                                    <p class="text-gray-500">
                                                                        {{ notification.data.message }}
                                                                    </p>
                                                                    <p class="text-xs text-gray-500">
                                                                        Received {{ $filters.getDateTimeFormatted(notification.created_at) }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </span>
                                                        <div v-if="notification.data.link" class="ml-4 mt-2 relative inline-block text-left">
                                                            <a :href="notification.data.link"
                                                               v-if="notification.data.link_type && notification.data.link_type === 'external'"
                                                               class="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                                               role="menuitem" tabindex="-1">
                                                                {{ notification.data.link_title || 'Details' }}
                                                            </a>
                                                            <inertia-link :href="notification.data.link"
                                                                          v-else
                                                                          class="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                                                          role="menuitem" tabindex="-1">
                                                                {{ notification.data.link_title || 'Details' }}
                                                            </inertia-link>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>
                                        </simple-bar>
                                        <simple-bar v-if="activeTab === 1" class="h-0 flex-1 flex flex-col overflow-y-auto overflow-x-hidden">
                                            <ul class="flex-1 divide-y divide-gray-200 overflow-hidden">
                                                <li v-if="!read.length">
                                                    <div class="group flex justify-between items-center p-4">
                                                        <span class="-m-1 p-1 block">
                                                            <div class="flex-1 flex items-center min-w-0 relative">
                                                                <div class="truncate">
                                                                    <div class="flex gap-2 pb-2 pl-4">
                                                                        <div class="rounded-full shadow w-10 h-10 overflow-hidden flex items-center justify-center text-gray-600">
                                                                            <svg class="h-6 w-6 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                                                            </svg>
                                                                        </div>
                                                                        <div>
                                                                            <p class="flex items-center text-sm font-medium text-gray-900  truncate">
                                                                                <span class="mt-1 mr-1">
                                                                                    No notifications
                                                                                </span>
                                                                            </p>
                                                                            <p class="text-sm text-gray-500 truncate">
                                                                                you haven't received any notifications yet.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </li>

                                                <li v-for="notification in read" class="px-6 py-5 relative">
                                                    <div class="group">
                                                        <span class="-m-1 p-1 block">
                                                            <div class="flex-1 flex items-center min-w-0 relative">
                                                                <div class="ml-4">
                                                                    <p class="font-medium text-gray-900">
                                                                        {{ notification.data.title }}
                                                                    </p>
                                                                    <p class="text-gray-500">
                                                                        {{ notification.data.message }}
                                                                    </p>
                                                                    <p class="text-xs text-gray-500">
                                                                        Received {{ $filters.getDateTimeFormatted(notification.created_at) }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </span>
                                                        <div v-if="notification.data.link" class="ml-4 mt-2 relative inline-block text-left">
                                                            <a :href="notification.data.link"
                                                               v-if="notification.data.link_type && notification.data.link_type === 'external'"
                                                               class="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                                               role="menuitem" tabindex="-1">
                                                                {{ notification.data.link_title || 'Details' }}
                                                            </a>
                                                            <inertia-link :href="notification.data.link"
                                                                          v-else
                                                                          class="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                                                          role="menuitem" tabindex="-1">
                                                                {{ notification.data.link_title || 'Details' }}
                                                            </inertia-link>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </simple-bar>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </teleport>
</template>

<script>
import Dropdown from '@/Components/Dropdown.vue'
import SimpleBar from 'simplebar-vue'

export default {
    props: ['show', 'notifications'],
    emits: ['close'],

    components: {
        Dropdown,
        SimpleBar
    },

    data () {
        return {
            tabs: [
                'Unread',
                'Read',
            ],
            activeTab: 0,
            read: this.notifications.read,
            unread: this.notifications.unread,
        }
    },

    watch: {
        show: function (value) {
            if (value === true) {
                if (this.unread.length) {
                    // Mark all unread as read for next page load...
                    axios.post(route('user.notifications.read'))
                }
            }
        }
    },

    methods: {
        close () {
            this.$emit('close')
        },
        changeTab (index) {
            this.activeTab = index
        }
    }
}
</script>
