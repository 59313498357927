<script setup>

import {
    useForm,
    // router
} from "@inertiajs/vue3";
import {onMounted, ref, watch} from "vue";
import DangerButton from "@/Components/DangerButton.vue";
//import SecondaryButton from "@/Components/SecondaryButton.vue";
import Button from "@/Components/Button.vue";
import ActionMessage from "@/Components/ActionMessage.vue";
import InputLabel from "@/Components/InputLabel.vue";
import swal from '@/swal'
import {useToast} from "vue-toastification";
import InputError from "@/Components/InputError.vue";
import TextInput from '@/Components/TextInput.vue';
import ModalFormSection from "@/Components/ModalFormSection.vue";
import InputInfoText from "@/Components/InputInfoText.vue";
import Slider from '@vueform/slider'
import Multiselect from "@vueform/multiselect";
import TwoWayToggleSwitch from "@/Components/TwoWayToggleSwitch.vue";

// Model variables
const modelTitle = 'Chat'
const modelTitleLower = 'chat'
const routeBase = 'chat'

const props = defineProps([
    'title',
    'description',
    'type',
    'model',
    'variables',

    // Form specific props for using CRUD-modal
    'defer_submit',
    'submitting',
    'errors'
])
const emit = defineEmits(['success', 'submit', 'destroy'])

const isReadyToSubmit = ref(true)
const styles = ref(['vivid', 'natural'])
const qualities = ref(['standard', 'hd'])
const types = ref(['chat', 'image', 'vision', 'speech'])
const speechModels = ref(['tts-1', 'tts-1-hd'])

const form = useForm({
    title: '',
    type: 'chat',
    endpoint: null,
    model: null,
    role: 'user',
    temperature: 0.5,
    size: props.variables.sizes[0] || null,
    n_items: '2',
    style: styles.value[0],
    quality: qualities.value[0],
    response_format: 'url',
    max_tokens: null,
    speech_voice: props.variables.voices[0] || 'alloy',
    speech_speed: 1,
})

onMounted(() => {
    // If model passed, pre-populate the form values
    if (props.model) {
        for (let key of Object.keys(form.data())) {
            if (props.model.hasOwnProperty(key)) {
                form[key] = props.model[key]
            }
        }
    }
})

const destroy = () => {
    swal.fire({
        title: `Delete ${modelTitleLower}`,
        text: 'This action cannot be undone',
        icon: 'warning',
        showCancelButton: true,
    }).then((result) => {
        if (result.isConfirmed) {
            const url = route(`${routeBase}.destroy`, props.model.id)
            if (props.defer_submit === true) {
                emit('destroy', {
                    method: 'delete',
                    url,
                    data: form.data()
                })
                return
            }
            form.delete(url, {
                preserveScroll: false,
                onSuccess: () => { emit('success') },
                onError: (error) => { useToast().error(Object.values(error).join(" ")) }
            })
        }
    })
}

const submit = () => {
    const method = props.type === 'POST' ? 'post' : 'patch'
    const url = props.type === 'POST'
        ? route(`${routeBase}.store`)
        : route(`${routeBase}.update`, props.model.id)

    if (props.defer_submit === true) {
        emit('submit', {
            method,
            url,
            data: form.data()
        })
        return
    }

    form[method](url, {
        preserveScroll: false,
        onSuccess: () => { emit('success') },
        onError: (error) => { useToast().error(Object.values(error).join(" ")) }
    })
}

</script>

<template>
    <div class="w-full h-full">
        <ModalFormSection @submitted="submit">
            <template #form>
                <div class="col-span-6">
                    <div class="mb-4 pb-3 border-b border-gray-200">
                        <InputLabel for="title" value="Title" :required="true" />
                        <TextInput
                            id="title"
                            type="text"
                            v-model="form.title"
                            class="mt-1 block w-full"
                        />
                        <InputError :message="form.errors.title" class="mt-2"/>
                    </div>

                    <div v-if="type === 'POST'" class="mb-3">
                        <InputLabel for="type" value="Type" :required="true" />
                        <Multiselect
                            :searchable="true"
                            v-model="form.type"
                            :options="types"
                        />
                        <InputError :message="form.errors.type" class="mt-2"/>
                        <InputInfoText>
                            Select <strong>chat</strong> for text generation (default), select <strong>image</strong> for image generation, select <strong>vision</strong> for image recognition and identification, or select <strong>speech</strong> for text-to-speech.
                        </InputInfoText>
                    </div>

                    <div v-if="form.type === 'chat'" class="mb-3">
                        <InputLabel for="model" value="Model" :required="true" />
                        <Multiselect
                            :searchable="true"
                            v-model="form.model"
                            :options="variables.models"
                        />
                        <InputError :message="form.errors.model" class="mt-2"/>
                        <InputInfoText>
                            Models have specific <a href="https://platform.openai.com/docs/models/overview" target="_blank" class="link">capabilities</a>.
                            Generally, models containing <strong>gpt-*</strong> work best for the <strong>chat</strong> endpoint and models without work best with <strong>completion</strong> endpoint.
                        </InputInfoText>
                    </div>

                    <div v-if="form.type === 'chat'" class="mb-3">
                        <InputLabel for="endpoint" value="Endpoint" :required="true" />
                        <Multiselect
                            :searchable="true"
                            v-model="form.endpoint"
                            :options="variables.endpoints"
                        />
                        <InputError :message="form.errors.endpoint" class="mt-2"/>
                    </div>

                    <div v-if="form.type === 'chat' || form.type === 'vision'" class="mb-3">
                        <InputLabel for="role" value="Role" :required="true" />
                        <Multiselect
                            :searchable="true"
                            v-model="form.role"
                            :options="variables.roles"
                        />
                        <InputError :message="form.errors.role" class="mt-2"/>
                        <InputInfoText>
                            The role of the messages author.
                        </InputInfoText>
                    </div>

                    <div v-if="form.type === 'chat' || form.type === 'vision'" class="mb-3">
                        <InputLabel
                            for="temperature"
                            :value="`Temperature${form.temperature ? ': ' + form.temperature : ''}`" />
                        <div class="my-2">
                            <Slider
                                v-model="form.temperature"
                                :max="2" :step="0.1"
                                showTooltip="drag"
                                :format="(num) => num ? num.toFixed(2) : 0"
                            />
                        </div>
                        <InputError :message="form.errors.temperature" class="mt-2"/>
                        <InputInfoText>
                            What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.
                        </InputInfoText>
                    </div>

                    <div v-if="form.type === 'chat' || form.type === 'vision'" class="mb-3">
                        <InputLabel for="max_tokens" value="Max Tokens" :required="form.type === 'vision'" />
                        <TextInput
                            id="max_tokens"
                            type="text"
                            v-model="form.max_tokens"
                            class="mt-1 block w-full"
                        />
                        <InputError :message="form.errors.max_tokens" class="mt-2"/>
                        <InputInfoText>
                            The maximum number of <a class="link" href="https://platform.openai.com/tokenizer" target="_blank">tokens</a> to generate in the chat completion. Leave blank to use defaults.
                        </InputInfoText>
                    </div>

                    <div v-if="form.type === 'image'" class="mb-3">
                        <InputLabel
                            for="n_items"
                            :value="`Number of items${form.n_items ? ': ' + form.n_items : ''}`"
                        />
                        <div class="my-2">
                            <Slider
                                v-model="form.n_items"
                                :min="1"
                                :max="10"
                                showTooltip="drag"
                            />
                        </div>
                        <InputError :message="form.errors.n_items" class="mt-2"/>
                    </div>

                    <div v-if="form.type === 'image'" class="mb-3">
                        <InputLabel for="size" value="Size" :required="true" />
                        <Multiselect
                            :searchable="true"
                            v-model="form.size"
                            :options="variables.sizes"
                        />
                        <InputError :message="form.errors.size" class="mt-2"/>
                    </div>

                    <div v-if="form.type === 'speech'" class="mb-3">
                        <InputLabel for="voice" value="Voice" :required="true" />
                        <Multiselect
                            :searchable="true"
                            v-model="form.speech_voice"
                            :options="variables.voices"
                        />
                        <InputError :message="form.errors.speech_voice" class="mt-2"/>
                    </div>

                    <div v-if="form.type === 'speech'" class="mb-3">
                        <InputLabel for="model" value="Model" :required="true" />
                        <Multiselect
                            :searchable="true"
                            v-model="form.model"
                            :options="speechModels"
                        />
                        <InputError :message="form.errors.model" class="mt-2"/>
                    </div>

                    <div v-if="form.type === 'speech'" class="mb-3">
                        <InputLabel
                            for="speech_speed"
                            :value="`Speed${form.speech_speed ? ': ' + form.speech_speed : ''}`"
                        />
                        <div class="my-2">
                            <Slider
                                v-model="form.speech_speed"
                                :min="0.25"
                                :step="0.25"
                                :max="4"
                                :format="{decimals: 2}"
                                showTooltip="drag"
                            />
                        </div>
                        <InputError :message="form.errors.speech_speed" class="mt-2"/>
                    </div>

                    <div v-if="form.type === 'image'" class="mb-3">
                        <InputLabel for="style" value="Style" :required="true" />
                        <Multiselect
                            :searchable="true"
                            v-model="form.style"
                            :options="styles"
                        />
                        <InputError :message="form.errors.style" class="mt-2"/>
                        <InputInfoText>
                            The style of the generated images. Vivid causes the model to lean towards generating hyper-real and dramatic images. Natural causes the model to produce more natural, less hyper-real looking images. The default is "vivid".
                        </InputInfoText>
                    </div>

                    <div v-if="form.type === 'image'" class="mb-3">
                        <InputLabel for="quality" value="Quality" :required="true" />
                        <Multiselect
                            :searchable="true"
                            v-model="form.quality"
                            :options="qualities"
                        />
                        <InputError :message="form.errors.quality" class="mt-2"/>
                        <InputInfoText>
                            The quality of the image that will be generated. HD creates images with finer details and greater consistency across the image. The default is "standard".
                        </InputInfoText>
                    </div>
                </div>
            </template>

            <template #actions>
                <DangerButton
                    v-if="type !== 'POST'"
                    type="button"
                    @click.native="destroy"
                    class="mr-3"
                    :class="{ 'opacity-25': form.processing || !isReadyToSubmit }"
                    :disabled="form.processing || !isReadyToSubmit"
                >
                    Delete
                </DangerButton>
                <ActionMessage :on="form.recentlySuccessful" class="ml-auto mr-3">
                    {{ type === 'POST' ? 'Created.' : 'Updated.'  }}
                </ActionMessage>
                <Button
                    :class="{ 'opacity-25': form.processing || submitting || !isReadyToSubmit }"
                    :disabled="form.processing || submitting || !isReadyToSubmit"
                >
                    {{ type === 'POST' ? 'Create' : 'Update' }}
                </Button>
            </template>
        </ModalFormSection>
    </div>
</template>
