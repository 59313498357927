<template>
    <div class="flex flex-col">
        <div class="overflow-x-auto shadow">
            <div class="align-middle inline-block min-w-full">
                <simple-bar class="tableFixHead">
                    <table class="min-w-full divide-y divide-gray-200 relative">
                        <thead class="bg-gray-50 text-left">
                        <slot name="head"></slot>
                        </thead>
                        <tbody v-if="hasBody" class="bg-white text-gray-900  divide-y divide-gray-200">
                        <slot name="body"></slot>
                        </tbody>
                        <slot />
                    </table>
                </simple-bar>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleBar from 'simplebar-vue'
export default {
    props: [],

    components: {
        SimpleBar
    },

    computed: {
        hasBody() {
            return !!this.$slots.body;
        }
    }
}
</script>

