import './bootstrap'
import '../css/app.css'

// Vue vendor components/extensions
import {createApp, h} from 'vue'
import {createInertiaApp} from '@inertiajs/vue3'
import {
    router,
    Head as InertiaHead,
    Link as InertiaLink,
} from '@inertiajs/vue3'
import NProgress from 'nprogress'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import Toast, { POSITION } from 'vue-toastification'
import {useToast} from 'vue-toastification'
import VueSplide from '@splidejs/vue-splide'
import vueFilePond, {setOptions} from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFilePoster from 'filepond-plugin-file-poster'
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size'
import FilePondPluginImageEditor from '@pqina/filepond-plugin-image-editor'

// Create file upload component
import {
    // Image editor
    openEditor,
    processImage,
    createDefaultImageReader,
    createDefaultImageWriter,
    createDefaultImageOrienter,

    // Import the editor default configuration
    getEditorDefaults,

    // The method used to register the plugins
    setPlugins,

    // The plugins we want to use
    plugin_crop,
    plugin_finetune,
    plugin_annotate,

    // The user interface and plugin locale objects
    locale_en_gb,
    plugin_crop_locale_en_gb,
    plugin_finetune_locale_en_gb,
    plugin_annotate_locale_en_gb,

    // Because we use the annotate plugin we also need
    // to import the markup editor locale and the shape preprocessor
    markup_editor_locale_en_gb,
    createDefaultShapePreprocessor,

    // Import the default configuration for the markup editor and finetune plugins
    markup_editor_defaults,
    plugin_finetune_defaults,
} from '@pqina/pintura';

// The default editor options
const editorOptions = {
    // Pass the editor default configuration options
    ...getEditorDefaults({
        /* Uncomment when editing videos
        locale: { ...plugin_trim_locale_en_gb },
        */
    }),

    // This will set a square crop aspect ratio
    //imageCropAspectRatio: 1,

    // // The markup editor default options, tools, shape style controls
    // ...markup_editor_defaults,
    //
    // // The finetune util controls
    // ...plugin_finetune_defaults,
    //
    // // This handles complex shapes like arrows / frames
    // shapePreprocessor: createDefaultShapePreprocessor(),
    //
    // // This will set a square crop aspect ratio
    // imageCropAspectRatio: 1,
    //
    // // The icons and labels to use in the user interface (required)
    // locale: {
    //     ...locale_en_gb,
    //     ...plugin_crop_locale_en_gb,
    //     ...plugin_finetune_locale_en_gb,
    //     ...plugin_annotate_locale_en_gb,
    //     ...markup_editor_locale_en_gb,
    // },
}

setOptions({
    server: {
        process: route("process.upload"),
        fetch: null,
        revert: null,
        headers: {
            "X-CSRF-TOKEN": document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content"),
            "X-Requested-With": "XMLHttpRequest",
        },
    },

    allowMultiple: false,

    // FilePond generic properties
    filePosterMaxHeight: 256,

    // Image Editor plugin properties
    imageEditor: {
        // Used to create the editor, receives editor configuration, should return an editor instance
        createEditor: openEditor,

        // Required, used for reading the image data
        imageReader: [createDefaultImageReader],

        // optionally. can leave out when not generating a preview thumbnail and/or output image
        imageWriter: [
            // Required when generating a preview thumbnail and/or output image
            createDefaultImageWriter,
            {
                targetSize: {
                    width: 1000,
                },
            },

            /* Uncomment when editing videos, remove above code
            () =>
                createDefaultMediaWriter(
                    // Generic Media Writer options, passed to image and video writer
                    {
                        targetSize: {
                            width: 400,
                        },
                    },
                    [
                        // For handling images
                        createDefaultImageWriter(),

                        // For handling videos
                        createDefaultVideoWriter({
                            // Video writer instructions here
                            // ...

                            // Encoder to use
                            encoder: createMediaStreamEncoder({
                                imageStateToCanvas,
                            }),
                        }),
                    ]
                ),
                */
        ],

        // Used to create poster and output images, runs an invisible "headless" editor instance
        imageProcessor: processImage,

        // Pintura Image Editor properties
        editorOptions: editorOptions,
    },

    credits: null,
});
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImageValidateSize,
    FilePondPluginFilePoster,
    FilePondPluginImageEditor
)

// Vendor libs
import axios from 'axios'
import Dinero from 'dinero.js'
import moment from 'moment'
import swal from './swal'
import EventBus from './eventBus'
import FloatingVue from 'floating-vue'
import { get, replace, eachRight } from 'lodash'
import * as Sentry from "@sentry/vue";

// Vue local components
import Layout from '@/Layouts/AppLayout.vue'
import NoTeamLayout from '@/Layouts/NoTeamLayout.vue'
import ModalLink from '@/Shared/ModalLink.vue'
import PageHeader from '@/Components/PageHeader.vue'

// Global event bus
const emitter = EventBus

// Get the app name
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'MyGPT';

// Create app
createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
        let page = pages[`./Pages/${name}.vue`]
        page.default.layout = name.startsWith('Auth/')
            ? NoTeamLayout
            : page.default.layout || Layout
        return page
    },
    setup({ el, App, props, plugin }) {
        return configureAndMountApp(createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(VueSplide)
            .use(Toast, {
                position: POSITION.BOTTOM_RIGHT
            })
            .use(FloatingVue, {
                distance: 10,
                instantMove: true
            })
            .component('modal-link', ModalLink)
            .component('ModalLink', ModalLink)
            .component('FilePond', FilePond)
            .component('InertiaHead', InertiaHead)
            .component('Head', InertiaHead)
            .component('PageHeader', PageHeader)
            .component('InertiaLink', InertiaLink), el)
    },
});

// Finish configuring the app and return the mounted element
function configureAndMountApp(App, el) {
    // Add filters
    App.config.globalProperties.$filters = {
        currencyUSD (value) {
            if (value) {
                return Dinero({
                    amount: Math.round(value * 100),
                    currency: 'usd'
                }).setLocale('en').toFormat('$0,0.00');
            }
            return 'N/A'
        },
        getDateRelative (date, format = 'YYYY-MM-DD HH:mm:ss') {
            if (!date) {
                return 'N/A'
            }
            // Parse UTC and pass back in local time
            return moment.utc(date, format).local().fromNow()
        },
        getDateFormatted (date, format = 'YYYY-MM-DD HH:mm:ss') {
            if (!date) {
                return 'N/A'
            }
            // Parse UTC and pass back in local time
            //return moment.utc(date, format).local().format('ll')
            return moment.utc(date, format).format('ll') // don't use `local()` for strictly date formatting, getting off by one
        },
        getTimeFormatted (date, format = 'YYYY-MM-DD HH:mm:ss') {
            if (!date) {
                return 'N/A'
            }
            // Parse UTC and pass back in local time
            return moment.utc(date, format).local().format('lll')
        },
        getDateTimeFormatted (date, format = 'YYYY-MM-DD HH:mm:ss') {
            if (!date) {
                return 'N/A'
            }
            // Parse UTC and pass back in local time
            return moment.utc(date, format).local().format('lll')
        },
        translate (string, args = {}, prefix = 'ux') {
            if (Array.isArray(string)) {
                let combined = []
                let value = ''
                for (let s of string) {
                    value = get(window.i18n, `${prefix}.${s}`)
                    eachRight(args, (paramVal, paramKey) => {
                        value = replace(value, `:${paramKey}`, paramVal)
                    })
                    if (value) {
                        combined.push(value)
                    } else {
                        console.warn('No translation for "' + s + '"')
                        combined.push(s)
                    }
                }
                return combined.join(" ")
            } else {
                let value = get(window.i18n, `${prefix}.${string}`)
                eachRight(args, (paramVal, paramKey) => {
                    value = replace(value, `:${paramKey}`, paramVal)
                })
                if (value) {
                    return value
                }
                console.warn('No translation for "' + string + '"')
                return string
            }
        }
    }

    // Emitter bus
    App.config.globalProperties.$emitter = emitter;

    // Add global properties
    App.config.globalProperties.$defaultDateFormat = 'YYYY-MM-DD'
    App.config.globalProperties.$defaultDateTimeFormat = 'YYYY-MM-DD hh:mm:ss'

    App.config.globalProperties.$dateTimeConfig = {
        wrap: false, // set wrap to true only when using 'input-group'
        altInput: true, // hides your original input and creates a new one with "human readable" format
        dateFormat: 'Z', // format passed to the backend, using ISO for maximum compatibility (https://flatpickr.js.org/formatting/)
        enableTime: true, // show time picker
        disableMobile: true, // prevent default mobile widgets being used
        altFormat: 'M j, Y h:iK', // customize format for the alt input
    }
    App.config.globalProperties.$dateConfig = {
        wrap: false, // set wrap to true only when using 'input-group'
        altInput: true, // hides your original input and creates a new one with "human readable" format
        dateFormat: 'Z', // format passed to the backend, using ISO for maximum compatibility (https://flatpickr.js.org/formatting/)
        disableMobile: true, // prevent default mobile widgets being used
        altFormat: 'M j, Y', // customize format for the alt input
    }
    App.config.globalProperties.$dateConfigSimple = {
        wrap: false, // set wrap to true only when using 'input-group'
        altInput: true, // hides your original input and creates a new one with "human readable" format
        dateFormat: 'Y-m-d', // format passed to the backend, using ISO for maximum compatibility (https://flatpickr.js.org/formatting/)
        disableMobile: true, // prevent default mobile widgets being used
        altFormat: 'M j, Y', // customize format for the alt input
    }
    App.config.globalProperties.$toast = () => {
        return useToast()
    }
    App.config.globalProperties.$swal = () => swal

    App.config.globalProperties.$openImageEditor = (imageUrl, callback) => {
        const editor = openEditor({
            ...editorOptions,
            src: imageUrl
        })
        editor.on('process', ({dest}) => {
            // Don't add file if cancelled
            if (!dest) return;

            // Call callback with new image URL
            callback && callback(dest)
        })
    }

    // Debugging
    Sentry.init({
        app: App,
        dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        integrations: [
            new Sentry.Replay(),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            /^https:\/\/mygpt\.test/,
            /^https:\/\/gpt\.brightmindedmedia\.com/,
        ],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });

    // Return the mounted App
    return App.mount(el)
}

// Setup axios for general requests
const instance = axios.create({
    validateStatus: false,
    headers: {
        common: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    },
})
instance.interceptors.response.use(
    function (response) {
        // continue sending response to the then() method
        return Promise.resolve(response)
    },
    function (error) {
        // check if unauthorized error returned
        if (error.response.status === 401) {
            window.location.href = '/login'
        }
        // request is rejected and will direct logic to the catch() method
        return Promise.reject(error)
    }
)

// Configure progress indicator
NProgress.configure({
    showSpinner: false
})

// Add interceptors
instance.interceptors.request.use((config) => {
    NProgress.start()
    return config
})
instance.interceptors.response.use((response) => {
    NProgress.done()
    NProgress.remove()
    return response
}, function (error) {
    NProgress.done()
    NProgress.remove()
})

// Make it globally accessible
window.axios = instance

// Configure Inertia progress indicator
router.on('start', () => NProgress.start())
router.on('finish', () => NProgress.done())
router.on('progress', (event) => {
    if (NProgress.isStarted() && event.detail.progress.percentage) {
        NProgress.set((event.detail.progress.percentage / 100) * 0.9)
    }
})
router.on('finish', (event) => {
    if (event.detail.visit.completed) {
        NProgress.done()
    } else if (event.detail.visit.interrupted) {
        NProgress.set(0)
    } else if (event.detail.visit.cancelled) {
        NProgress.done()
        NProgress.remove()
    }
})

