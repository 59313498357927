<template>
    <div>
        <div class="nightwind-prevent-block bg-gradient-to-r from-primary-500 to-primary-300"
             v-if="show && message">
            <div class="max-w-screen-xl mx-auto py-1 px-3 sm:px-6 lg:px-8">
                <div class="flex items-center justify-between flex-wrap">
                    <div class="w-0 flex-1 flex items-center min-w-0">
                        <span class="flex bg-opacity-25 p-2 rounded-lg">
                            <svg class="h-5 w-5 text-white"
                                 xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round"
                                      stroke-linejoin="round" stroke-width="2"
                                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </span>
                        <p class="ml-1 font-medium text-sm text-white truncate" v-html="message" />
                    </div>
                    <div class="flex-shrink-0 sm:ml-3">
                        <inertia-link :href="route('profile.show')"
                           type="button"
                           class="-mr-1 hover:bg-primary-500 focus:bg-primary-500 text-sm font-medium text-white flex p-2 rounded-md focus:outline-none sm:-mr-2 transition ease-in-out duration-150">
                            Add API Key
                        </inertia-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            show: !this.$page.props.auth.user.open_ai_key,
        }
    },

    computed: {
        message () {
            if (!this.$page.props.auth.user.open_ai_key) {
                return `You are currently using <strong>${this.$page.props.appName}</strong> without an API key. Until you add your API key functionality will be limited.`
            }
            return ''
        },
    }
}
</script>
