<template>
    <!-- Flyout sidebar for mobile -->
    <teleport to="body">
        <transition leave-active-class="duration-200">
            <div v-show="show" class="fixed inset-0 overflow-y-auto px-4 py-6 sm:px-0 z-50" scroll-region>
                <transition
                    enter-active-class="ease-out duration-300"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="ease-in duration-200"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <div v-show="show" class="fixed inset-0 transform transition-all">
                        <div class="absolute inset-0 bg-gray-500 opacity-75" />
                    </div>
                </transition>

                <transition enter-active-class="transform transition ease-in-out duration-500"
                            enter-from-class="-translate-x-full"
                            enter-to-class="translate-x-0"
                            leave-active-class="transform transition ease-in-out duration-500"
                            leave-from-class="translate-x-0"
                            leave-to-class="-translate-x-full">
                    <div v-show="show"
                         class="fixed inset-0 overflow-hidden"
                         aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
                        <div class="absolute inset-0 overflow-hidden">
                            <div class="fixed inset-y-0 left-0 pr-10 flex sm:pr-16">
                                <div class="w-screen max-w-sm">

                                    <div class="fixed z-10 inset-0 transform transition-all" @click="close">
                                        <div class="absolute" />
                                    </div>

                                    <div class="relative z-20 h-full flex flex-col bg-white shadow-xl overflow-y-auto">
                                        <div class="mt-5 flex-1 h-0 overflow-y-auto overflow-x-hidden">
                                            <div class="p-6">
                                                <div class="flex items-start justify-between">
                                                    <h2 class="text-lg font-medium text-gray-900 " id="slide-over-title">
                                                        Navigation
                                                    </h2>
                                                    <div class="ml-3 h-7 flex items-center">
                                                        <button @click="close" type="button" class="bg-white  rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-primary-500">
                                                            <span class="sr-only">Close panel</span>
                                                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <nav class="px-2 divide-y divide-gray-200">
                                                <div class="space-y-1">
                                                    <sidebar-mobile-nav-item
                                                        v-for="(link, idx) in links"
                                                        :key="`desktop_link_${idx}`"
                                                        :title="link.title"
                                                        :active="link.activeUrl ? $page.url.includes(link.activeUrl) : $page.component.startsWith(link.activeComponent)"
                                                        :url="link.url"
                                                        :has-dropdown="link.dropdown"
                                                        :collapsed="isCollapsed"
                                                    >
                                                        <template #svg>
                                                            <path stroke-linecap="round" stroke-linejoin="round" :d="link.iconPath" />
                                                        </template>
                                                        <template v-if="link.dropdown" #links>
                                                            <div v-for="(child, cIdx) in link.children" :key="`desktop_link_${idx}_c_${cIdx}`">
                                                                <div class="block px-4 py-2 text-xs text-gray-400">
                                                                    {{ child.heading }}
                                                                </div>
                                                                <DropdownLink v-for="link in child.links" :as="link.as" :href="link.url" :active="link.activeUrl ? $page.url.includes(link.activeUrl) : $page.component.startsWith(link.activeComponent)">
                                                                    {{ link.title }}
                                                                </DropdownLink>
                                                            </div>
                                                        </template>
                                                    </sidebar-mobile-nav-item>
                                                </div>

                                            </nav>
                                        </div>
                                        <div v-if="showFooterSection" class="flex-shrink-0 flex border-t border-gray-200 p-4">
                                            <!-- Mobile footer slot -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </teleport>

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:flex lg:flex-shrink-0 relative z-10 shadow-xl">
        <!-- Sidebar collapse toggle -->
        <button
            @click="toggleCollapse"
            :class="{
                '': !isCollapsed,
                '': isCollapsed
            }"
            class="absolute p-1 bottom-3 -right-3 rounded-full bg-gray-100 shadow z-5 text-gray-400 hover:bg-gray-300 hover:text-gray-500 activeComponent:bg-gray-200 focus:outline-none focus:border-gray-100 focus:shadow-outline-gray">
            <svg
                :class="{
                    'rotate-0': !isCollapsed,
                    'rotate-180': isCollapsed
                }"
                class="w-4 h-4 transform transition-transform ease-in-out duration-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
        </button>
        <div
            :class="{
                'w-64': !isCollapsed,
                'w-14': isCollapsed
            }"
            class="flex flex-col border-r border-gray-200 pt-5 pb-4 bg-white transition-all ease-in-out duration-300">
            <simple-bar class="h-0 flex-1 flex flex-col overflow-y-auto overflow-x-hidden">
                <!-- Navigation -->
                <nav class="px-3 mt-3 divide-y divide-gray-200">
                    <div class="space-y-1">
                        <sidebar-nav-item
                            v-for="(link, idx) in links"
                            :key="`desktop_link_${idx}`"
                            :title="link.title"
                            :active="link.activeUrl ? $page.url.includes(link.activeUrl) : $page.component.startsWith(link.activeComponent)"
                            :url="link.url"
                            :has-dropdown="link.dropdown"
                            :collapsed="isCollapsed"
                            >
                            <template #svg>
                                <path stroke-linecap="round" stroke-linejoin="round" :d="link.iconPath" />
                            </template>

                            <template v-if="link.dropdown" #links>
                                <div v-for="(child, cIdx) in link.children" :key="`desktop_link_${idx}_c_${cIdx}`">
                                    <div class="block px-4 py-2 text-xs text-gray-400">
                                        {{ child.heading }}
                                    </div>
                                    <DropdownLink v-for="link in child.links" :as="link.as" :href="link.url" :active="link.activeUrl ? $page.url.includes(link.activeUrl) : $page.component.startsWith(link.activeComponent)">
                                        {{ link.title }}
                                    </DropdownLink>
                                </div>
                            </template>
                        </sidebar-nav-item>
                    </div>
                </nav>
            </simple-bar>
            <div v-if="showFooterSection" class="flex-shrink-0 flex items-center border-t border-gray-200 py-4"
                 :class="{
                    'px-4': !isCollapsed,
                    'justify-center': isCollapsed
                 }"
            >
                <!-- Desktop footer slot -->
            </div>
        </div>
    </div>
</template>

<script>
import DropdownLink from '@/Components/DropdownLink.vue'
import SidebarNavItem from '@/Shared/SidebarNavItem.vue'
import SidebarMobileNavItem from '@/Shared/SidebarMobileNavItem.vue'
import SimpleBar from 'simplebar-vue'

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        collapsed: {
            type: Boolean,
            default: true
        }
    },

    emits: ['close', 'update:collapsed'],

    components: {
        SidebarNavItem,
        SidebarMobileNavItem,
        DropdownLink,
        SimpleBar
    },

    data() {
        return {
            isCollapsed: this.collapsed,
            showFooterSection: false,
            links: [
                // {
                //     title: 'Dashboard',
                //     dropdown: false,
                //     url: route('dashboard'),
                //     activeComponent: 'Dashboard',
                //     activeUrl: null,
                //     children: [
                //         {
                //             heading: 'Group heading',
                //             links: [
                //                 {
                //                     title: 'Dashboard',
                //                     url: route('dashboard'),
                //                     activeComponent: 'Dashboard',
                //                     activeUrl: null,
                //                     as: 'modal' // button, a, or modal
                //                 }
                //             ]
                //         }
                //     ],
                //     iconPath: 'M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z',
                // },
                {
                    title: 'Users',
                    dropdown: false,
                    url: route('user.index'),
                    activeComponent: 'Users',
                    activeUrl: null,
                    children: [],
                    iconPath: 'M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z'
                },
                {
                    title: 'Chats',
                    dropdown: false,
                    url: route('chat.admin.index'),
                    activeComponent: null,
                    activeUrl: 'admin/chat',
                    children: [],
                    iconPath: 'M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155',
                }
            ]
        }
    },

    methods: {
        close () {
            this.$emit('close')
        },
        toggleCollapse () {
            this.$emit('update:collapsed', !this.isCollapsed)
        }
    },

    watch: {
        isCollapsed: function (val) {
            this.$emit('update:collapsed', val)
        },
        collapsed: function (val) {
            this.isCollapsed = val
        }
    }
}
</script>
