<template>
    <div class="my-1 text-xs text-gray-400">
        <p v-if="message">
            {{ message }}
        </p>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: ['message']
    }
</script>
