<script setup>

import {useForm, router, Link} from "@inertiajs/vue3";
import {watch} from "vue";
import {debounce, pickBy} from "lodash";
import Breadcrumbs from "@/Shared/Breadcrumbs.vue";
import SearchFilter from "@/Shared/SearchFilter.vue";
import Swal from '@/swal'
import Table from "@/Shared/Table.vue";
import Pagination from "@/Shared/Pagination.vue";
import TableActionButton from "@/Shared/TableActionButton.vue";
import ColumnSort from "@/Shared/ColumnSort.vue";
import {useToast} from "vue-toastification";

// Model variables
const modelTitle = 'Chat'
const modelTitleLower = 'chat'
const routeBase = 'chat'

/*
 * Table properties
 */
const props = defineProps([
    'filters',
    'create_url',
    'models'
])
const tableForm = useForm({
    search: props.filters.search,
    order: props.filters.order,
    trashed: props.filters.trashed,
})
watch(() => tableForm, () => search)
const search = debounce(function () {
    let query = pickBy(tableForm.data())
    router.get(route(`${routeBase}.index`, Object.keys(query).length ? query : { remember: 'forget' }))
}, 500)

/*
 * Model actions
 */
const destroy = (model) => {
    Swal.fire({
        title: `Delete ${modelTitleLower}`,
        text: 'This action cannot be undone',
        icon: 'warning',
        showCancelButton: true,
    }).then((result) => {
        if (result.isConfirmed) {
            router.delete(route(`${routeBase}.destroy`, model.id), {
                preserveScroll: false,
                onSuccess: () => { router.reload() },
                onError: (error) => { useToast().error(Object.values(error).join(" ")) }
            })
        }
    })
}

const reset = () => {
    tableForm.reset()
}

const getCrumbs = () => {
    return [
        { title: modelTitle, href: null },
    ]
}

</script>

<template>
    <Head :title="modelTitle" />

    <div>
        <PageHeader>{{ modelTitle }}</PageHeader>

        <Breadcrumbs :links="getCrumbs()" />

        <div class="flex flex-row flex-wrap content-center justify-between py-6 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <ModalLink
                :href="create_url"
                class="inline-flex items-center px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray transition ease-in-out duration-150"
            >
                <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                Create new chat
            </ModalLink>

            <SearchFilter
                v-model="tableForm.search" class="w-full max-w-md"
                @reset="reset"
            />
        </div>

        <div class="pb-4">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div class="bg-white  overflow-hidden shadow-xl sm:rounded-lg">
                    <Table>
                        <template #head>
                            <th scope="col"
                                class="relative px-6 py-3">
                                <span class="sr-only">Edit</span>
                            </th>
                            <th scope="col" class="px-6 py-3 tracking-wider">
                                <ColumnSort name="Title" column="title" v-model="tableForm.order"/>
                            </th>
                            <th scope="col" class="px-6 py-3 tracking-wider">
                                <ColumnSort name="Endpoint" column="endpoint" v-model="tableForm.order"/>
                            </th>
                            <th scope="col" class="px-6 py-3 tracking-wider">
                                <ColumnSort name="Model" column="model" v-model="tableForm.order"/>
                            </th>
                            <th scope="col" class="px-6 py-3 tracking-wider">
                                <ColumnSort name="Role" column="role" v-model="tableForm.order"/>
                            </th>
                            <th scope="col" class="px-6 py-3 tracking-wider">
                                <ColumnSort name="Temperature" column="temperature" v-model="tableForm.order"/>
                            </th>
                            <th scope="col" class="px-6 py-3 tracking-wider">
                                <ColumnSort name="Max Tokens" column="max_tokens" v-model="tableForm.order"/>
                            </th>
                        </template>
                        <template #body>
                            <tr v-for="model in models.data">
                                <td class="px-6 py-4 text-right text-sm font-medium">
                                    <TableActionButton
                                        :actions="[
                                            {href: model.show_url, title: 'View'},
                                            {
                                                title: 'Edit',
                                                action: () => $emitter.emit('modelAddEdit', {
                                                    url: model.edit_url,
                                                    refresh: true
                                                })
                                            },
                                            {divider: true},
                                            {title: 'Delete', action: () => destroy(model)},
                                        ]"
                                    />
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <InertiaLink :href="model.show_url" class="text-sm link">
                                        {{ model.title }}
                                    </InertiaLink>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <p class="text-sm">
                                        {{ model.endpoint }}
                                    </p>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <p class="text-sm">
                                        {{ model.model }}
                                    </p>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <p class="text-sm">
                                        {{ model.role }}
                                    </p>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <p class="text-sm">
                                        {{ model.temperature || 'N/A' }}
                                    </p>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <p class="text-sm">
                                        {{ model.max_tokens || 'N/A' }}
                                    </p>
                                </td>
                            </tr>
                        </template>
                    </Table>
                    <Pagination :links="models.links" />
                </div>
            </div>
        </div>
    </div>
</template>
