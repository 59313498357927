<script setup>

import {useForm, router, Link} from "@inertiajs/vue3";
import {watch} from "vue";
import {debounce, pickBy} from "lodash";
import SimpleBar from 'simplebar-vue'
import Breadcrumbs from "@/Shared/Breadcrumbs.vue";
import SearchFilter from "@/Shared/SearchFilter.vue";
import Swal from '@/swal'
import Table from "@/Shared/Table.vue";
import Pagination from "@/Shared/Pagination.vue";
import TableActionButton from "@/Shared/TableActionButton.vue";
import ColumnSort from "@/Shared/ColumnSort.vue";
import {useToast} from "vue-toastification";
import AccordionItem from "@/Shared/AccordionItem.vue";
import ModalLink from "@/Shared/ModalLink.vue";
import Button from "@/Components/Button.vue";
import SecondaryButton from "@/Components/SecondaryButton.vue";

// Model variables
const modelTitle = 'User'
const modelTitleLower = 'user'
const routeBase = 'user'

/*
 * Table properties
 */
const props = defineProps([
    'filters',
    'create_url',
    'models',
    'variables'
])
const tableForm = useForm({
    search: props.filters.search,
    order: props.filters.order,
    trashed: props.filters.trashed,
})
watch(() => tableForm, () => search)
const search = debounce(function () {
    let query = pickBy(tableForm.data())
    router.get(route(`${routeBase}.index`, Object.keys(query).length ? query : { remember: 'forget' }))
}, 500)

/*
 * Model actions
 */
const destroy = (model) => {
    Swal.fire({
        title: `Delete ${modelTitleLower}`,
        text: 'This action cannot be undone',
        icon: 'warning',
        showCancelButton: true,
    }).then((result) => {
        if (result.isConfirmed) {
            router.delete(route(`${routeBase}.destroy`, model.id), {
                preserveScroll: false,
                onSuccess: () => { router.reload() },
                onError: (error) => { useToast().error(Object.values(error).join(" ")) }
            })
        }
    })
}

const reset = () => {
    tableForm.reset()
}

const getCrumbs = () => {
    return [
        { title: 'Admin', href: null },
        { title: modelTitle, href: null },
    ]
}

</script>

<template>
    <Head :title="modelTitle" />

    <div>
        <PageHeader>{{ modelTitle }}</PageHeader>

        <Breadcrumbs :links="getCrumbs()" />

        <div class="flex flex-row gap-2 flex-wrap content-center justify-between py-6 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <ModalLink :href="create_url" class="inline-flex items-center px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray transition ease-in-out duration-150">
                <svg class="w-4 h-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                Create new user
            </ModalLink>

            <SearchFilter
                v-model="tableForm.search" class="w-full max-w-md ml-auto"
                @reset="reset"
            />
        </div>

        <div class="pb-4">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div class="bg-white shadow-xl sm:rounded-lg">
                    <SimpleBar class="overflow-auto h-auto max-h-[50vh]">
                        <ul role="list" class="inline-block divide-y divide-gray-100 px-6 py-4 min-w-full">
                            <li v-for="(model, idx) in models.data" :key="model.id" class="relative min-w-full">
                                <AccordionItem type="chat details">
                                    <template #title>
                                        <div class="flex items-center gap-x-4 whitespace-nowrap">
                                            <ModalLink :href="model.edit_url" class="ml-2 w-8 h-8 rounded-full overflow-hidden flex items-center border border-2 border-primary-700 hover:border-primary-500">
                                                <img class="w-full h-auto" :src="model.profile_photo_url" alt="User" />
                                            </ModalLink>
                                            <ModalLink :href="model.edit_url" class="ml-2 link">
                                                {{ model.name }}
                                            </ModalLink>
                                        </div>
                                    </template>
                                    <template #actions>
                                        <TableActionButton
                                            :actions="[
                                                {
                                                    title: 'Edit',
                                                    action: () => $emitter.emit('modelAddEdit', {
                                                        url: model.edit_url,
                                                        refresh: true
                                                    })
                                                },
                                                {href: model.impersonate_url, title: 'Impersonate'},
                                                {divider: true},
                                                {title: 'Delete', action: () => destroy(model)},
                                            ]"
                                        />
                                    </template>
                                    <template #meta>
                                        <div class="flex gap-1 items-center">
                                            <div class="">
                                                <span class="inline-flex items-center gap-x-1.5 bg-gray-50 rounded-full px-2 py-1 text-xs font-bold text-gray-900 ring-1 ring-inset ring-gray-200">
                                                    Chats
                                                    <span class="rounded-full bg-primary-500 text-white px-1 py-0.5 flex items-center">
                                                        {{ model.chat_count || 'N/A' }}
                                                    </span>
                                                </span>
                                            </div>
                                            <span class="inline-flex items-center rounded-full whitespace-nowrap bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                                {{ model.email }}
                                            </span>
                                            <svg v-if="model.is_admin" class="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                <path fill-rule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                    </template>
                                    <template #content>
                                        <div class="px-4 sm:px-0">
                                            <h3 class="text-base font-semibold leading-7 text-gray-900">
                                                User details
                                            </h3>
                                            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                                                User profile details
                                            </p>
                                        </div>
                                        <div class="mt-6 border-t border-gray-100">
                                            <dl class="divide-y divide-gray-100">
                                                <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                    <dt class="text-sm font-medium leading-6 text-gray-900">
                                                        Created
                                                    </dt>
                                                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                        {{ $filters.getTimeFormatted(model.created_at) }}
                                                    </dd>
                                                </div>
                                                <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                    <dt class="text-sm font-medium leading-6 text-gray-900">
                                                        Admin
                                                    </dt>
                                                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                        {{ model.is_admin ? 'True' : 'False' }}
                                                    </dd>
                                                </div>
                                                <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                    <dt class="text-sm font-medium leading-6 text-gray-900">
                                                        Name
                                                    </dt>
                                                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                        {{ model.name }}
                                                    </dd>
                                                </div>
                                                <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                    <dt class="text-sm font-medium leading-6 text-gray-900">
                                                        Email
                                                    </dt>
                                                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                        {{ model.email }}
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                    </template>
                                </AccordionItem>
                            </li>
                        </ul>
                    </SimpleBar>
                    <Pagination class="pb-4" :links="models.links" />
                </div>
            </div>
        </div>
    </div>
</template>
