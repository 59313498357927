<template>
    <div>
        <teleport to="body">
            <transition leave-active-class="duration-200">
                <div v-show="showingFormModal" class="fixed inset-0 overflow-y-auto px-4 py-6 sm:px-0 z-50" scroll-region>
                    <transition
                        enter-active-class="ease-out duration-300"
                        enter-from-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="ease-in duration-200"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0"
                    >
                        <div v-show="showingFormModal" class="fixed inset-0 transform transition-all">
                            <div class="absolute inset-0 bg-gray-500 opacity-75" />
                        </div>
                    </transition>
                    <transition enter-active-class="transform transition ease-in-out duration-500"
                                enter-from-class="translate-x-full"
                                enter-to-class="translate-x-0"
                                leave-active-class="transform transition ease-in-out duration-500"
                                leave-from-class="translate-x-0"
                                leave-to-class="translate-x-full">
                        <div v-show="showingFormModal"
                             class="fixed inset-0 overflow-hidden"
                             aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
                            <div class="absolute inset-0 overflow-hidden">
                                <div class="fixed inset-y-0 right-0 pl-10 flex sm:pl-16 pl-5">
                                    <div class="w-screen max-w-xl">
                                        <div class="fixed z-10 inset-0 transform transition-all" @click="showingFormModal = false">
                                            <div class="absolute" />
                                        </div>
                                        <div class="relative z-20 h-full flex flex-col curves-bg shadow-xl overflow-y-auto">
                                            <div class="p-6">
                                                <div class="flex items-start justify-between">
                                                    <h2 class="text-lg font-medium text-gray-900" id="slide-over-title">
                                                        {{ title }}
                                                    </h2>
                                                    <div class="ml-3 h-7 flex items-center">
                                                        <button @click="showingFormModal = false" type="button" class="bg-white  rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-primary-500">
                                                            <span class="sr-only">Close panel</span>
                                                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div v-if="loadingForm" class="p-6">
                                                    <loader :loading="loadingForm" />
                                                </div>
                                                <div v-if="!loadingForm && componentName">
                                                    <component
                                                        :is="asyncComponent"
                                                        v-bind="componentProps"
                                                        ref="form"
                                                        :errors="formErrors"
                                                        :defer_submit="true"
                                                        :submitting="submittingForm"
                                                        @submit="onSubmit"
                                                        @destroy="onDestroy"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </transition>
        </teleport>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'

import Loader from '@/Shared/Loader.vue'

export default {
    components: {
        Loader,
    },

    created() {
        this.$emitter.on('modelAddEdit', (args) => {
            // Get the component variables
            const { url, refresh } = args

            // Update local variables
            this.shouldRefresh = refresh

            // If re-opening same component don't refresh
            if (this.url !== url) {
                // Update the url
                this.url = url

                // If new URL, clear out component details
                this.reset()
            }

            // Display modal
            this.showingFormModal = true
        })
    },

    watch: {
        showingFormModal: function (showing) {
            // If component name not set fetch the form
            if (showing && !this.componentName) {
                this.loadForm()
            }

            // On hide, clear out the component name
            if (!showing) {
                // Need to reset form on close, so it doesn't persist to next component
                this.$refs.form?.form?.reset()
                this.$refs.form?.form?.clearErrors()
                this.componentName = null
            }
        }
    },

    computed: {
        asyncComponent: function () {
            const componentName = this.componentName
            return defineAsyncComponent(
                () => resolvePageComponent(`../Pages/${componentName}.vue`, import.meta.glob('../Pages/**/*.vue'))
            );
        }
    },

    data() {
        return {
            title: null,
            url: null,
            componentName: null,
            componentProps: {},
            headers: {
                'X-Inertia': true,
                'X-Inertia-Version': this.$page.version,
            },
            formErrors: {},
            loadingForm: false,
            submittingForm: false,
            showingFormModal: false,
            shouldRefresh: false
        }
    },

    methods: {
        loadForm() {
            this.reset()
            this.loadingForm = true

            // Append `is_json` url parameter to the request
            const url = this.url.includes('?')
                ? `${this.url}&is_json=true`
                : `${this.url}?is_json=true`

            // Fetch the form props (using Inertia headers)
            axios.get(url, { headers: this.headers })
                .then((response) => {
                    const { data } = response
                    const responseURL = response.request.responseURL
                    if (responseURL !== url) {
                        // Received a redirect
                        this.$inertia.get(responseURL)
                        this.showingFormModal = false
                        return
                    }
                    if (data.hasOwnProperty('errors')) {
                        this.$toast().error(Object.values(data.errors).join(" "))
                    } else {
                        // Set the component props
                        this.componentProps = data.props || {}

                        // Grab the title from the props
                        this.title = this.componentProps.title || null

                        // Update the component
                        this.componentName = data.component || null
                    }
                }).finally(() => this.loadingForm = false)
        },
        reset() {
            // Reset variables
            this.title = 'Loading...'
            this.componentName = null
            this.componentProps = {}
            this.formErrors = {}
            this.submittingForm = false
        },
        onSubmit({ method, url, data }) {
            const formData = Object.assign({ is_json: true }, data)
            this.submittingForm = true
            axios[method](url, formData, { headers: this.headers })
                .then(({ data }) => {
                    if (data.hasOwnProperty('errors')) {
                        this.formErrors = data.errors
                        this.$toast().error(Object.values(data.errors).join(" "))
                    } else {
                        if (data.hasOwnProperty('return_url') || this.shouldRefresh) {
                            const url = data.return_url || route(route().current(), route().params)
                            this.$inertia.get(url)
                        } else {
                            this.$toast().success('Updated successfully')
                        }
                        this.showingFormModal = false
                    }
                }).finally(() => this.submittingForm = false)
        },
        onDestroy({ method, url, data }) {
            const formData = Object.assign({ is_json: true }, data)
            this.submittingForm = true
            axios[method](url, formData, { headers: this.headers })
                .then(({ data }) => {
                    if (data.hasOwnProperty('errors')) {
                        this.formErrors = data.errors
                        this.$toast().error(Object.values(data.errors).join(" "))
                    } else {
                        if (data.hasOwnProperty('return_url') || this.shouldRefresh) {
                            const url = data.return_url || route(route().current(), route().params)
                            this.$inertia.get(url)
                        } else {
                            this.$toast().success('Deleted successfully')
                        }
                        this.showingFormModal = false
                    }
                }).finally(() => this.submittingForm = false)
        }
    }
}
</script>
