<template>
    <template v-if="hasDropdown">
        <Dropdown align="left" width="60">
            <template #trigger>
                <span
                    class="cursor-pointer group relative flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md"
                    :class="{
                        'bg-gray-200 text-gray-900': active,
                        'text-gray-700 hover:text-gray-900 hover:bg-gray-100': !active,
                    }">
                    <svg
                        class="mr-3 h-6 w-6"
                        :class="{
                            'text-gray-900': active,
                            'text-gray-700 group-hover:text-gray-900': !active,
                        }"
                        xmlns="http://www.w3.org/2000/svg" :fill="iconFill ? iconFill : 'none'" viewBox="0 0 24 24" :stroke="iconFill ? 'none' : 'currentColor'" aria-hidden="true">
                        <slot name="svg"></slot>
                    </svg>
                    <span class="pr-6">
                        {{ title }}
                        <span v-if="activityIndicator" class="absolute top-2 left-2 block h-2 w-2 rounded-full ring-2 ring-white bg-green-400" />
                    </span>
                    <svg class="h-6 w-6 absolute right-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </span>
            </template>
            <template #content>
                <slot name="links"></slot>
            </template>
        </Dropdown>
    </template>
    <template v-else>
        <inertia-link
            :href="url"
            class="group relative flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md"
            :class="{
                'bg-gray-200 text-gray-900': active,
                'text-gray-700 hover:text-gray-900 hover:bg-gray-100': !active,
            }">
            <svg
                class="mr-3 h-6 w-6"
                :class="{
                    'text-gray-900': active,
                    'text-gray-700 group-hover:text-gray-900': !active,
                }"
                xmlns="http://www.w3.org/2000/svg" :fill="iconFill ? iconFill : 'none'" viewBox="0 0 24 24" :stroke="iconFill ? 'none' : 'currentColor'" aria-hidden="true">
                <slot name="svg"></slot>
            </svg>
            {{ title }}
            <slot></slot>
            <span v-if="activityIndicator" class="absolute top-2 left-2 block h-2 w-2 rounded-full ring-2 ring-white bg-green-400" />
        </inertia-link>
    </template>
</template>
<script>

import Dropdown from '@/Components/Dropdown.vue'
export default {
    props: ['active', 'url', 'title', 'hasDropdown', 'activityIndicator', 'iconFill'],

    components: {Dropdown}
}
</script>
