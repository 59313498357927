<template>
    <div>
        <template v-if="hasDropdown">
            <Dropdown align="left" width="60">
                <template #trigger>
                    <span
                        v-tooltip.right="collapsed ? title : ''"
                        class="cursor-pointer group relative flex items-center text-sm font-medium rounded-md transition-all ease-in-out duration-300"
                        :class="{
                            'px-2 py-2 text-sm': !collapsed,
                            'px-0.5 py-1 mb-2': collapsed,
                            'bg-gray-200 text-gray-900': active,
                            'text-gray-700 hover:text-gray-900 hover:bg-gray-100': !active,
                        }">
                        <svg
                            class="h-6 w-6 transition-all ease-in-out duration-300"
                            :class="{
                                'mr-3': !collapsed,
                                'flex-1': collapsed,
                                'text-gray-900': active,
                                'text-gray-700 group-hover:text-gray-900': !active,
                            }"
                            xmlns="http://www.w3.org/2000/svg" :fill="iconFill ? iconFill : 'none'" viewBox="0 0 24 24" :stroke="iconFill ? 'none' : 'currentColor'" aria-hidden="true">
                            <slot name="svg"></slot>
                        </svg>
                        <span v-if="!collapsed" class="pr-6">
                            {{ title }}
                            <span v-if="activityIndicator"
                                  class="absolute block h-2 w-2 rounded-full ring-2 ring-white bg-green-400"
                                  :class="{
                                    'top-2 left-2': !collapsed,
                                    'top-1 left-1': collapsed
                                  }"
                            />
                        </span>
                        <svg
                            :class="{
                                'h-4 w-4 -right-0.5 -bottom-0.5 bg-opacity-90': collapsed,
                                'h-6 w-6 right-1 ': !collapsed
                            }"
                            class="absolute transition-all ease-in-out duration-300 bg-gray-100 text-gray-500 rounded-full shadow-sm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </span>
                </template>
                <template #content>
                    <slot name="links"></slot>
                </template>
            </Dropdown>
        </template>
        <template v-else>
            <inertia-link
                :href="url"
                v-tooltip.right="collapsed ? title : ''"
                class="group relative flex items-center font-medium rounded-md transition-all ease-in-out duration-300"
                :class="{
                    'px-2 py-2 text-sm': !collapsed,
                    'px-0.5 py-1 mb-2': collapsed,
                    'bg-gray-200 text-gray-900': active,
                    'text-gray-700 hover:text-gray-900 hover:bg-gray-100': !active,
                }">
                <svg
                    class="h-6 w-6 transition-all ease-in-out duration-300"
                    :class="{
                        'mr-3': !collapsed,
                        'flex-1': collapsed,
                        'text-gray-900': active,
                        'text-gray-700 group-hover:text-gray-900': !active,
                    }"
                    xmlns="http://www.w3.org/2000/svg" :fill="iconFill ? iconFill : 'none'" viewBox="0 0 24 24" :stroke="iconFill ? 'none' : 'currentColor'" aria-hidden="true">
                    <slot name="svg"></slot>
                </svg>
                <template v-if="!collapsed">
                    {{ title }}
                    <slot></slot>
                </template>
                <span v-if="activityIndicator"
                      class="absolute block h-2 w-2 rounded-full ring-2 ring-white bg-green-400"
                      :class="{
                        'top-2 left-2': !collapsed,
                        'top-1 left-1': collapsed
                      }"
                />
            </inertia-link>
        </template>
    </div>
</template>
<script>

import Dropdown from '@/Components/Dropdown.vue'
export default {
    props: ['active', 'url', 'title', 'hasDropdown', 'activityIndicator', 'iconFill', 'collapsed'],

    components: {Dropdown}
}
</script>
