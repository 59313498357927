<template>
    <form @submit.prevent="$emit('submitted')" class="flex flex-col h-full" style="height: calc(100vh - 5rem)">
        <div class="py-5 flex-1">
            <div class="grid grid-cols-6 gap-6">
                <slot name="form"></slot>
            </div>
        </div>

        <div class="flex items-center justify-end py-3 bg-gray-100 text-right -mx-6 -mb-6 px-6" v-if="hasActions">
            <slot name="actions"></slot>
        </div>
    </form>
</template>

<script>
    import JetSectionTitle from './SectionTitle.vue'

    export default {
        emits: ['submitted'],

        components: {
            JetSectionTitle,
        },

        computed: {
            hasActions() {
                return !! this.$slots.actions
            }
        }
    }
</script>
