<script setup>
import UrlButton from '@/Shared/UrlButton.vue'
import Logo from '@/Components/Logo.vue'
import moment from 'moment'
import {onMounted, ref} from 'vue'

const date = ref(moment())

onMounted(() => {
    setInterval(() => {
        date.value = moment()
    }, 1000) // update time every second

})

</script>

<template>
    <div>
        <div class="p-6 sm:px-20 bg-white border-b border-gray-200">
            <div class="sm:flex items-start justify-between">
                <div>
                    <div class="flex items-center just gap-2">
                        <img class="rounded-full w-14 h-14"
                             :src="$page.props.auth.user.profile_photo_url"
                             alt="avatar" />
                        <span class="text-2xl text-gray-900 ">
                            Hello, {{ $page.props.auth.user.name }}
                        </span>
                    </div>

                    <div class="mt-6 text-gray-500">
                        Welcome to <strong>{{ $page.props.appName }}</strong>!
                    </div>
                </div>
                <div class="sm:text-right sm:mt-0 mt-2" v-if="date">
                    <p class="text-gray-400 text-sm">
                        Today is
                    </p>
                    <p class="text-gray-600 font-medium">
                        {{ date.format('MMM Do, YYYY') }}
                    </p>
                    <p class="text-xs text-gray-400">
                        {{ date.format('h:mm:ss a') }}
                    </p>
                </div>
            </div>
        </div>

        <div class="bg-gray-200 bg-opacity-25 grid grid-cols-1 md:grid-cols-2">
            <div class="p-6 sm:px-20 col-span-1 md:col-span-2 flex flex-row gap-4">
                <div class="flex-shrink-0">
                    <Logo size-class="w-20 sm:w-28 mr-0 sm:mr-8" />
                </div>
                <div class="flex-grow">
                    <div class="mt-2 text-sm text-gray-500">
                        Create and manage your chats. You can create multiple, and each can have a unique configuration.
                        Tweak and update your chats to reflect your desired experience.
                    </div>

                    <UrlButton class="mt-8" :url="route('chat.index')">
                        View and manage chats
                    </UrlButton>
                </div>
            </div>
        </div>
    </div>
</template>
