<template>
    <div class="mb-3 flex flex-wrap">
        <nav
            class="w-full border-t border-gray-200 px-6 flex items-center justify-between sm:px-4 ">
            <template v-for="(link, index) in links">

                <div v-if="index === 0"
                     class="-mt-px w-0 flex-1 flex">
                    <template v-if="link.url === null">
                        <a href="#"
                           class="cursor-not-allowed border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-400 hover:text-gray-400 hover:border-gray-300">
                            <svg class="mr-3 h-5 w-5 text-gray-400"
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true">
                                <path fill-rule="evenodd"
                                      d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                                      clip-rule="evenodd" />
                            </svg>
                            Previous
                        </a>
                    </template>
                    <template v-else>
                        <inertia-link :href="link.url"
                                      class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                            <svg class="mr-3 h-5 w-5 text-gray-400"
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true">
                                <path fill-rule="evenodd"
                                      d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                                      clip-rule="evenodd" />
                            </svg>
                            Previous
                        </inertia-link>
                    </template>
                </div>

                <div v-else-if="index === links.length - 1"
                     class="-mt-px w-0 flex-1 flex justify-end">
                    <template v-if="link.url === null">
                        <a href="#"
                           class="cursor-not-allowed border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-400 hover:text-gray-400 hover:border-gray-300">
                            Next
                            <svg class="ml-3 h-5 w-5 text-gray-400"
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true">
                                <path fill-rule="evenodd"
                                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                      clip-rule="evenodd" />
                            </svg>
                        </a>
                    </template>
                    <template v-else>
                        <inertia-link :href="link.url"
                                      class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                            Next
                            <svg class="ml-3 h-5 w-5 text-gray-400"
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true">
                                <path fill-rule="evenodd"
                                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                      clip-rule="evenodd" />
                            </svg>
                        </inertia-link>
                    </template>
                </div>

                <div v-else class="hidden md:-mt-px md:flex">
                    <span v-if="!link.url">
                        <span
                            class="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
                            {{ link.label }}
                        </span>
                    </span>
                    <span v-else>
                        <inertia-link :href="link.url" :class="{
                            'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium': !link.asctive,
                            'border-primary-500 text-primary-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium': link.active
                        }">
                            {{ link.label }}
                        </inertia-link>
                    </span>
                </div>
            </template>
        </nav>
    </div>
</template>

<script>
export default {
    props: ['links'],
}
</script>
